import React from 'react'

// @mui/material components
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'
import classNames from 'classnames'

import BaseComponent from '../components/_Base'
import Splash from '../components/Splash/Splash'
import MainAppBar from '../components/Main/MainAppBar'
import withSharedFeatures from './withSharedFeatures'
import AppState from '../managers/AppStateManager'
// jss
import { root, container } from '../assets/jss/index'

import EventsTable from '../components/Public/EventsTable'
import EventDataAndImages from '../components/Public/EventDataAndImages'

import { C_EVENT_IDS, C_MODULE_NAME } from '../variables/common'
import { Typography } from '@mui/material';

const styles = (theme) => ({
  root: {
    ...root,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  },
  container: {
    ...container,
    margin: 0,
    paddingRight: 0,
    paddingLeft: 0,
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    "& div.app-bar": {
      flex: 'none',
    },
    "& div.content": {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      "& div.no-event-panel": {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      "& div.top-panel": {
        flex: 2,
        width: '100%',
        backgroundColor: 'black',
        "& div.img-container": {
          padding: 10,
          outline: 'none',
        },
        "& div.img-container > img": {
          border: '1px solid #c1c1c1',
          borderRadius: 3,
        },
        "& > div.event-detail": {
          padding: '5px 10px 5px 10px'
        }
      },
      "& div.bottom-panel": {
        flex: 3,
        width: '100%',
      }
    }
  },
  gridContainer: {
    flexGrow: 1,
    height: '100vh',
    display: 'flex',
    justifyContent: 'center'
  },
  toolbar: theme.mixins.toolbar,
})

class Search extends BaseComponent {

  _handleOnSelectedEvent = (event) => {
    const { events } = this.state
    const evt = events.find(e => e._id === event._id)
    AppState.currentPublicEventId = event._id
    this.setState({
      event: evt
    })
  }

  _handleOnSearchEvents = (eventList) => {
    AppState.currentPublicEventId = ''
    this.setState({
      events: eventList,
      event: null
    })
  }

  constructor(props) {
    super(props)
    // pattern of extending state from the base class
    const baseState = { ...this.state }

    document.title = props.title;

    // determine the referrer....
    let isReady = false
    if (props.location.state && props.location.state.prevPath) {
      isReady = true
    } else {
      isReady = AppState.publicEventList.length > 0
    }
    this.state = {
      ...baseState,
      isReady: isReady,
      events: AppState.publicEventList,
      event: AppState.selectedPublicEvent,
    }
    AppState.currentModuleName = C_MODULE_NAME.C_SEARCH_PAGE
  }

  componentWillMount() {
    this.props.changeTheme('baseTheme')
  }

  componentDidMount() {

    super.componentDidMount()

    if (!this.state.isReady && AppState.publicEventList.length === 0) {
      AppState.getPublicEvents((res) => {
        if (res.success) {
          const events = res.data
          setTimeout(() => {
            this.setState({
              isReady: true,
              events: events,
            })
          }, 1000)
        } else {
          console.log('DEBUG:error', res.error)
        }
      })
    }
    AppState.addEventListener(C_EVENT_IDS.C_ON_SEARCH_EVENTS, this._handleOnSearchEvents)
  }

  componentWillUnmount() {
    AppState.removeEventListener(C_EVENT_IDS.C_ON_SEARCH_EVENTS, this._handleOnSearchEvents)
  }

  render() {
    const { classes, ...rest } = this.props;
    let { isReady, events, event } = this.state;
    events = events || [];
    const noEventsFound = events.length === 0;
    const withEvents = !noEventsFound;
    

    if (withEvents === true) {
      if (!event) {
        if (AppState.currentPublicEventId) {
          event = events.find(e => e._id === AppState.currentPublicEventId)
          if (!event) {
            event = events[0]
            AppState.currentPublicEventId = event._id
          }
        } else {
          event = events[0]
          AppState.currentPublicEventId = event._id
        }
      }
    }

    // console.log('DEBUG:UI event', event, events)

    return (
      <div className={classNames('full-screen', classes.root)}>
        {!isReady && (
          <div className={classes.gridContainer}>
            <Splash showLogo={false} />
          </div>
        )}
        {isReady && (
          <div className={classes.container}>
            <div className='app-bar'>
              <MainAppBar showSearchButton={true} {...rest} />
            </div>
            <div className={classes.toolbar}></div>
            <div className='content'>
              {noEventsFound &&
                <div className='no-event-panel'>
                  <Typography variant='h6'>No events found.</Typography>
                </div>
              }
              {withEvents &&
                <React.Fragment>
                  <div className='top-panel'>
                    <EventDataAndImages event={event} {...rest} />
                  </div>
                  <div className='bottom-panel'>
                    <EventsTable events={events} event={event} onSelectedEvent={this._handleOnSelectedEvent} {...rest} />
                  </div>
                </React.Fragment>
              }
            </div>
          </div>
        )}
      </div>
    )
  }
}

Search.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withSharedFeatures(withStyles(styles)(Search))
