import moment from 'moment'
import lodash from 'lodash'

let _timerId;

class Util {

    get _() {
        return lodash
    }

    orderBy = (arr, fields, fieldsOrder) => {
        return lodash.orderBy(arr, fields, fieldsOrder)
    }

    filterScore = (arr, filterOptions) => {
        // base case, if no filterOptions return full array
        if (filterOptions.length === 0) {
            return arr;
        }
        // if filterOptions.length > 0, filter with filterOptions
        return lodash.filter(arr, (element) => {
            return filterOptions.includes(element.assessmentScore.assessment);
        })
    }

    typeOf = (obj) => {
        return Object.prototype.toString.call(obj).slice(8, -1)
    }

    cloneDeep = (anyObject) => {
        return lodash.cloneDeep(anyObject)
    }

    cloneArray = (arr) => {
        const newArr = arr.map(a => ({ ...a }))
        return newArr
    }

    assign = lodash.assign

    formatDate = (date, format = 'MM/DD/YYYY HH:mm:ss.SSS', gmt = true) => {
        const d = moment.utc(date)
        return d.format(format) + (gmt ? ' UTC' : '')
    }

    isEqualByValue = (arr1, arr2) => {
        return lodash.isEqual(arr1, arr2)
    }

    debounce = (fn, delay) => {
        return lodash.debounce(fn, delay)
    }

    debounce2 = (fn, delay) => {
        let context = this
        clearTimeout(_timerId)
        _timerId = setTimeout(() => {
            fn.apply(context)
        }, delay)
        return _timerId
    }

    sendAnalyticsData = (location) => {
        clearTimeout(_timerId)
        _timerId = setTimeout(() => {
            console.log("DEBUG: sendAnalyticsData", location, window.ga)
            window.ga && window.ga('send', 'pageview', location)
        }, 100)
    }

    getWindowSize = () => {
        let viewportWidth = 0
        let viewportHeight = 0
        if (typeof window.innerWidth !== 'undefined') {
            // the more standards compliant browsers (mozilla/netscape/opera/IE7) use window.innerWidth and window.innerHeight
            viewportWidth = window.innerWidth
            viewportHeight = window.innerHeight
        } else if (typeof document.documentElement !== 'undefined' && typeof document.documentElement.clientWidth !== 'undefined' && document.documentElement.clientWidth !== 0) {
            // IE6 in standards compliant mode (i.e. with a valid doctype as the first line in the document)
            viewportWidth = document.documentElement.clientWidth
            viewportHeight = document.documentElement.clientHeight
        } else {
            // older versions of IE
            viewportWidth = document.getElementsByTagName('body')[0].clientWidth
            viewportHeight = document.getElementsByTagName('body')[0].clientHeight
        }
        return {
            width: viewportWidth,
            height: viewportHeight
        }
    }

    isValidTimeValue = (timeValue) => {
        const re = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])(.[0-9][0-9][0-9])?$/;
        return re.test(timeValue);
    }

    isValidEmail = (email) => {
        const re = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/
        return re.test(email);
    }

    isValidDateValue = (value) => {
        const tmpValue = new Date(value)
        return tmpValue.toString() !== 'Invalid Date'
    }

    generatePolygon = (bolideFilter) => {
        let { latitude, longitude, latitudeDelta, longitudeDelta } = bolideFilter

        latitude = typeof(latitude) === 'number' ? latitude : parseFloat(latitude);
        longitude = typeof(longitude) === 'number' ? longitude : parseFloat(longitude);
        latitudeDelta = typeof(latitudeDelta) === 'number' ? latitudeDelta : parseFloat(latitudeDelta);
        longitudeDelta = typeof(longitudeDelta) === 'number' ? longitudeDelta : parseFloat(longitudeDelta);
        latitudeDelta = latitudeDelta < 1.0 ? 1.0 : latitudeDelta;
        longitudeDelta = longitudeDelta < 1.0 ? 1.0 : longitudeDelta;

        let x1 = longitude - longitudeDelta
        if (x1 < -180) { x1 = -180 }
        let y1 = latitude - latitudeDelta
        if (y1 < -90) { y1 = -90 }
        let x2 = longitude + longitudeDelta
        if (x2 > 180) { x2 = 180 }
        let y2 = latitude - latitudeDelta
        if (y2 < -90) { y2 = -90 }

        let x3 = x2
        let y3 = latitude + latitudeDelta
        if (y3 > 90) { y3 = 90 }
        let x4 = x1
        let y4 = y3

        // rectangle shape
        const point1 = [x1, y1]
        const point2 = [x2, y2]
        const point3 = [x3, y3]
        const point4 = [x4, y4]

        const polygon = [point1, point2, point3, point4, point1]

        return polygon
    }
}

export default new Util()
