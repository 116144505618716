import React, { useRef, useEffect, useState } from "react"
import { makeStyles } from '@mui/styles'
import Grow from '@mui/material/Grow'
import IconButton from '@mui/material/IconButton'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit'
import ZoomInIcon from '@mui/icons-material/ZoomIn'
import ZoomOutIcon from '@mui/icons-material/ZoomOut'
import ResetZoomIcon from '@mui/icons-material/Search'
import useResizeObserver from "../UseResizeObserver/UseResizeObserver"
import worldGeoJson from '../../assets/map/world.geo.json'
import Util from '../../utils/Util'
import AppState from '../../managers/AppStateManager'
import { C_EVENT_IDS, C_LEGEND_LABEL_MAP, C_GLM_COLOR, C_GLM_LegendItems } from '../../variables/common'
import EventsPopper from '../EventsPopper/EventsPopper'

import * as d3 from 'd3'

const { select, selectAll, geoPath, geoMercator, geoGraticule, min, max, scaleLinear } = d3
// geoEqualEarth, geoOrthographic, geoEquirectangular, 

const useStyles = makeStyles({
    root: {
        fontSize: '100%',
        '& svg .marker:hover': {
            strokeColor: 'rgb(255,255,255)',
            strokeWidth: 0.6,
        },
        '& .graticule': {
            fill: 'none',
            stroke: '#343434',
            strokeWidth: 1,
            strokeDasharray: '1,1'
        },
        '& .zoom-control': {
            position: 'absolute',
            top: 55,
            width: 50,
            zIndex: 38,
            right: 0,
            backgroundColor: 'transparent',
            '& button': {
                backgroundColor: '#111',
                color: 'gray',
                '&:hover': {
                    backgroundColor: '#232323',
                    color: 'whitesmoke'
                },
                marginBottom: 5,
            }
        },
        '& .full-screen-button': {
            position: 'absolute',
            bottom: 5,
            zIndex: 38,
            right: 5,
            backgroundColor: '#111',
            color: 'gray',
            '&:hover': {
                backgroundColor: '#232323',
                color: 'whitesmoke'
            }
        },
    },
})

const property = 'gdp_md_est'
const minProp = min(worldGeoJson.features, feature => feature.properties[property])
const maxProp = max(worldGeoJson.features, feature => feature.properties[property])
const colorScale = scaleLinear()
    .domain([minProp, maxProp])
    .range(["#333", "#666"])
const markerScale = scaleLinear()
    .domain([300, 1000])
    .range([0.5, 1.5])

const C_MIN_MAP_HEIGHT = 400
const C_DEFAULT_MAP_WIDTH = 823
const C_BASE_SCALE_MAP_HEIGHT = {
    k: 1.65,
    x: -296.75,
    y: -270.84
}
const calculateScaleMapBasedOnHeight = (h,w) => {
    const tmpH = h //|| height
    const tmpW = w //|| width
    
    let newY = C_BASE_SCALE_MAP_HEIGHT.y
    let newX = C_BASE_SCALE_MAP_HEIGHT.x
    if (tmpH !== C_MIN_MAP_HEIGHT) {
        newY = (tmpH * C_BASE_SCALE_MAP_HEIGHT.y) / C_MIN_MAP_HEIGHT
    }
    if (tmpW !== C_DEFAULT_MAP_WIDTH) {
        newX = (tmpW * C_BASE_SCALE_MAP_HEIGHT.x) / C_DEFAULT_MAP_WIDTH
    }
    return {
        k: C_BASE_SCALE_MAP_HEIGHT.k,
        x: newX,
        y: newY
    } 
}

function EventsWorldMap({ enableInteraction, showHideAdvSearch, events, poly, ...rest }) {
    const classes = useStyles()
    const svgRef = useRef()
    const wrapperRef = useRef()
    const dimensions = useResizeObserver(wrapperRef)

    const [eventList, setEventList] = useState(events)
    const [width, setWidth] = useState(400)
    const [height, setHeight] = useState(C_MIN_MAP_HEIGHT)
    const [viewBox, setViewBox] = useState(`0 0 ${width} ${height}`)
    const [rotation, ] = useState([110, 0, 0])
    const [scale, setScale] = useState(null)
    const [isShowLegend, setIsShowLegend] = useState(false)
    const [isFullscreen, setIsFullscreen] = useState(AppState.isFullscreen)
    const [popperTargetElement, setPopperTargetElement] = useState(null)
    const [selectedEventId, setSetSelectedEventId] = useState(null)
    
    const legendItems = Object.keys(C_GLM_LegendItems).map(k => {
        const mc = C_GLM_LegendItems[k]
        return {
            label: mc.label,
            fill: mc.fill,
            stroke: mc.stroke
        }
    })
    const tmp = Util.cloneArray(legendItems)
    const g16Legend = tmp.splice(0,4)
    const g17Legend = tmp.splice(0,4)
    const stereoLegend = tmp.splice(0,4)

    
    const handlePopperOnMouseLeave = () => {
        setPopperTargetElement(null)
    }
    
    if (events.length > AppState.totalNumberOfEvents) {
        AppState.totalNumberOfEvents = events.length
    }
    const numberOfEventsLabel = (AppState.totalNumberOfEvents === events.length) ? `Total number of bolides: ${events.length}` : `Selected number of bolides: ${events.length}`
    
    const d3MouseWithScale = (d3mouseXY) => {
        const xy = d3mouseXY
        const t = d3.zoomTransform(svgRef.current)
        const x = (xy[0] - t.x)/t.k
        const y = (xy[1] - t.y)/t.k
        return [x,y]   
    }

    // will be called initially and on every data change
    useEffect(() => {
        const _container = select(wrapperRef.current)
        const _svg = select(svgRef.current)
        const _boundingRect = dimensions || wrapperRef.current.getBoundingClientRect()
        if (_boundingRect.height < C_MIN_MAP_HEIGHT) _boundingRect.height = C_MIN_MAP_HEIGHT

        const _view = `0 0 ${_boundingRect.width} ${_boundingRect.height}`

        if (!scale) {
            const tmpTransform = d3.zoomTransform(svgRef.current)
            if (AppState.currentScale) {
                tmpTransform.k = AppState.currentScale.k
                tmpTransform.x = AppState.currentScale.x
                tmpTransform.y = AppState.currentScale.y
            } 
            else {
                const s = calculateScaleMapBasedOnHeight(_boundingRect.height, _boundingRect.width)
                tmpTransform.k = s.k
                tmpTransform.x = s.x
                tmpTransform.y = s.y
            }
            setScale(tmpTransform)
        }

        setWidth(_boundingRect.width)
        setHeight(_boundingRect.height)
        setViewBox(_view)

        AppState.currentScale = scale

        const projection = geoMercator().precision(1000000)
            .fitSize([width, height], worldGeoJson)
            .rotate(rotation)

        // transforms that into the d attribute of a path element
        const pathGenerator = geoPath().projection(projection)

        _svg
            .selectAll('path, circle.marker') // To prevent stroke width from scaling
            .attr('transform', scale)

        const zoomed = () => {
            setPopperTargetElement(null)
            const t = d3.event.transform
            t.x = t.x > 0 ? 0 : t.x 
            t.y = t.y > 0 ? 0 : t.y
            setScale(t)
            // _svg
            //     .selectAll('path') // To prevent stroke width from scaling
            //     .attr('transform', d3.event.transform);
        }

        const zoom = d3.zoom()
            .scaleExtent([1, 15])
            .on('zoom', zoomed)


        _svg.call(zoom)

        // zoom controls....
        // const _zoomControl = 
        selectAll('.zoom-control, .full-screen-button')
            .on('mouseenter', function() {
                setPopperTargetElement(null)
            })
        // const _zoomInButton = 
        select('.zoom-in-button')
            .on("click", function () {
                _svg.transition().duration(750).call(zoom.scaleBy, 2)
            })
        // const _zoomOutButton = 
        select('.zoom-out-button')
            .on("click", function () {
                _svg.transition().duration(750).call(zoom.scaleBy, 0.5)
            })
        // const _resetZoomButton = 
        select('.reset-zoom-button')
            .on("click", function () {
                resetScale()
            })

        //------ graticule with labels -----------
        const graticule = geoGraticule()
            .step([10, 10])      // graticule steps (default 10,10)
            .precision(1.0);               // graticule precision (° ; default 2.5°)

        _svg
            .selectAll(".graticule")
            .data([graticule()])
            .join("path")
            .attr("class", "graticule")
            .attr("d", pathGenerator)

        // render each country
        _svg
            .selectAll(".country")
            .data(worldGeoJson.features)
            .join("path")
            .attr("class", "country")
            .attr("fill", feature => colorScale(feature.properties[property]))
            .attr('stroke', '#ffffff')
            .attr('stroke-width', 0.15)
            .attr("d", feature => pathGenerator(feature))

        const markers_coords = []
        const markers = events.map((m) => {
            const proj = projection([m.longitude, m.latitude])
            const xLon = proj[0]
            const yLat = proj[1]
            let detectedBy = m.detectedBy ? m.detectedBy.trim() : ''
            let colorKeys = detectedBy ? detectedBy.split(',').map(m => m.trim()) : []
            if (colorKeys.length > 0 && m.confidenceRating.length > 0) {
                colorKeys.push(m.confidenceRating)
            }
            const colorKey = colorKeys.join(',')
            const mc = C_GLM_COLOR[colorKey]
            let label = colorKey
            const fillColor = mc ? mc.fill : 'rgba(238, 238, 0, 0.5)'
            const strokeColor = mc ? mc.stroke : 'rgba(238, 238, 0, 1)'
            markers_coords.push([m.longitude, m.latitude])
            return {
                id: m._id,
                label: label,
                cx: xLon,
                cy: yLat,
                r: markerScale(width),
                fillColor: fillColor,
                strokeColor: strokeColor
            }
        })

        if (markers_coords.length > 0) {
            markers_coords.push(markers_coords[0])
        }
        _svg
            .selectAll('.marker')
            .data(markers)
            .join('circle')
            .attr('class', 'marker')
            .attr('cx', c => c.cx)
            .attr('cy', c => c.cy)
            .attr('r', c => c.r)
            .attr('fill', c => c.fillColor)
            .attr('stroke', c => c.strokeColor)
            .attr('stroke-width', 1.0)
            .on('mouseenter', c => {
                setSetSelectedEventId(c.id)
                handleMarkerOnMouseEnter(c, d3.event)
            })

        _svg
            .selectAll('.search_boundary')
            .data([{
                type: "LineString",
                coordinates: poly
            }])
            .join("path")
            .attr('class', 'search_boundary')
            .attr('stroke', c => '#FF00FF')
            .attr('fill', '#ff00ff')
            .attr('fill-opacity', 0.3)
            .attr("d", pathGenerator)
        
        _svg.on("click", function () {
            // const t = d3.zoomTransform(svgRef.current)
            const xy = d3.mouse(this)
            const xy2 = d3MouseWithScale(d3.mouse(this))
            console.log('DEBUG:UI click mouse', projection.invert(xy))
            console.log('DEBUG:UI click mouse with scale', projection.invert(xy2))
        })

        // // // TODO: uncomment for debugging
        // _svg.on('mousemove', function () {
        //     const xyScaled = d3MouseWithScale(d3.mouse(this))
        //     const xy = d3.mouse(this)
        //     const lonLat = projection.invert(xy)
        //     const lonLatScaled = projection.invert(xyScaled)
        //     const tooltipText = `Unscaled|x:${Math.round(xy[0])} y:${Math.round(xy[1])} <-> lon:${lonLat[0].toFixed(2)} lat:${lonLat[1].toFixed(2)} 
        //                         <br /> 
        //                         Scaled|x:${Math.round(xyScaled[0])} y:${Math.round(xyScaled[1])} <-> lon:${lonLatScaled[0].toFixed(2)} lat:${lonLatScaled[1].toFixed(2)}
        //                         <br />
        //                         svg dimension: w=${width}, h=${height} | viewbox:${viewBox}
        //                         <br />
        //                         scale: k=${scale.k.toFixed(2)}, x=${scale.x.toFixed(2)}, y=${scale.y.toFixed(2)}`
        //     const coord = select('.map-legend .coordinate')
        //         .html(tooltipText)
        //     // console.log('DEBUG:UI lon / lat', tooltipText)
        // })

        _container.on('mouseleave', function () {
            Util.debounce2(() => {
                if (!AppState.suppressPopperClosing) {
                    setPopperTargetElement(null)        
                }
            }, 500)
        })
        .on('click', () => {
            setPopperTargetElement(null)
        })

        const resetScale = () => {
            const rect = wrapperRef.current.getBoundingClientRect() // dimensions || wrapperRef.current.getBoundingClientRect()
            const tmpTransform = d3.zoomTransform(svgRef.current)
            const s = calculateScaleMapBasedOnHeight(rect.height, rect.width)
            tmpTransform.k = s.k
            tmpTransform.x = s.x
            tmpTransform.y = s.y
            setScale(tmpTransform)

            _svg.transition().duration(1000).call(
                zoom.transform,
                tmpTransform, // d3.zoomIdentity,
                d3.zoomTransform(_svg.node()).invert([width / 2, height / 2])
            )
        }
        const handleMarkerOnMouseEnter = (data, e) => {
            setPopperTargetElement(e.target)
        }
        const handleOnFullscreen = () => {
            const fs = AppState.toggleScreenMode()
            setIsFullscreen(fs)
            resetScale()
        }
        const handleOnSearchEvents = (eventList) => {
            setPopperTargetElement(null)
            setEventList(eventList)
        }
        const handleOnScreenModeChanged = (fullscreen) => {
            if(fullscreen !== isFullscreen) {
                setIsFullscreen(fullscreen)
                resetScale()
            }
        }
        const handleOnWindowResized = () => {
            Util.debounce2(() => {
                resetScale()
            }, 100)
        }
        const handleShowHideLegend = () => {
            setIsShowLegend(!isShowLegend)
        }
    
        // handlers 
        select('.full-screen-button')
            .on('click', handleOnFullscreen)
        select('.show-hide-legend-button')
            .on('click', handleShowHideLegend)

        AppState.addEventListener(C_EVENT_IDS.C_ON_SEARCH_EVENTS, handleOnSearchEvents)
        AppState.addEventListener(C_EVENT_IDS.C_ON_SCREEN_MODE_CHANGED, handleOnScreenModeChanged)
        AppState.addEventListener(C_EVENT_IDS.C_ON_WINDOW_RESIZED, handleOnWindowResized)
        return () => {
            AppState.removeEventListener(C_EVENT_IDS.C_ON_SEARCH_EVENTS, handleOnSearchEvents)
            AppState.removeEventListener(C_EVENT_IDS.C_ON_SCREEN_MODE_CHANGED, handleOnScreenModeChanged)
            AppState.removeEventListener(C_EVENT_IDS.C_ON_WINDOW_RESIZED, handleOnWindowResized)
        }
    }, [events, eventList, dimensions, height, width, viewBox, scale, rotation, showHideAdvSearch, poly, isFullscreen, isShowLegend])

    return (
        <div className={classes.root} ref={wrapperRef} style={{ backgroundColor: 'black', height: '100%', position: 'relative' }}>
            <div className="number-of-events" style={{color: 'whitesmoke', pointerEvents: 'none', position:'absolute', bottom:5, textAlign: 'left', width: '100%', backgroundColor: 'transparent', fontSize: '0.885rem', userSelect: 'none' }}>
                <strong style={{padding: 5, backgroundColor: 'rgba(0,0,0,0.6)', borderRadius: 3, border: '1px solid #212121', fontSize: '0.75rem', pointerEvents: 'none'}}>{numberOfEventsLabel}</strong>
            </div>
            <div className="map-legend">
                <div className="coordinate" style={{display: 'none', top:50, pointerEvents: 'none', position: 'relative', zIndex: 2, padding: 5, border: '1px solid white', backgroundColor: 'black'}}></div>
                <div className='show-hide-legend-button select-none' style={{pointerEvents: 'all', width: 'auto'}}>{`${isShowLegend ? 'Hide': 'Show'} Legend`}</div>
                <Grow in={isShowLegend} timeout={1000}>
                    <div className="content">
                        <div className="sub-content">
                            {g17Legend.map((l, key) => {
                                const circleStyle = {
                                    backgroundColor: l.fill,
                                    border: `3px solid ${l.stroke}`
                                }
                                return (
                                    <div key={key} className="item">
                                        <span className='circle' style={circleStyle}></span>
                                        <span className='label-text'>{l.label}</span>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="sub-content">
                            {stereoLegend.map((l, key) => {
                                const circleStyle = {
                                    backgroundColor: l.fill,
                                    border: `3px solid ${l.stroke}`
                                }
                                return (
                                    <div key={key} className="item">
                                        <span className='circle' style={circleStyle}></span>
                                        <span className='label-text'>{l.label}</span>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="sub-content">
                            {g16Legend.map((l, key) => {
                                const circleStyle = {
                                    backgroundColor: l.fill,
                                    border: `3px solid ${l.stroke}`
                                }
                                return (
                                    <div key={key} className="item">
                                        <span className='circle' style={circleStyle}></span>
                                        <span className='label-text'>{l.label}</span>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </Grow>
            </div>
            <svg ref={svgRef} width={width} height={height} viewBox={viewBox} preserveAspectRatio="xMidYMin" style={{display: 'block'}}>
            </svg>
            <div className='zoom-control'>
                <IconButton className='zoom-in-button' aria-label="zoom-in-button" size="large">
                    <ZoomInIcon></ZoomInIcon>
                </IconButton> 
                <IconButton className='reset-zoom-button' aria-label="reset-zoom" size="large">
                    <ResetZoomIcon></ResetZoomIcon>
                </IconButton>
                <IconButton className='zoom-out-button' aria-label="zoom-out-button" size="large">
                    <ZoomOutIcon></ZoomOutIcon>
                </IconButton>            
            </div>
            {AppState.isFullscreenSupported &&
                <IconButton
                    className='full-screen-button'
                    aria-label="fullscreen"
                    color="primary"
                    size="large">
                    {isFullscreen ? <FullscreenExitIcon title='Exit Fullscreen' /> : <FullscreenIcon title="Fullscreen" />}
                </IconButton>
            }
            <EventsPopper events={eventList} selectedEventId={selectedEventId} {...rest} targetEl={popperTargetElement} onMouseLeave={handlePopperOnMouseLeave} />
        </div>
    );
}

EventsWorldMap.defaultProps = {
    enableInteraction: false
}

export default EventsWorldMap
