import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'
import { Typography } from '@mui/material'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import ArtTrackIcon from '@mui/icons-material/ArtTrack'
import Checkbox from '@mui/material/Checkbox'
import SendIcon from '@mui/icons-material/Send'
import ClearIcon from '@mui/icons-material/Clear'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import moment from 'moment'
import Divider from '@mui/material/Divider'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import classNames from 'classnames'

import _Base from '../_Base'
import AppState from '../../managers/AppStateManager'
import EventSearchResultStat from '../EventSearchResultStat/EventSearchResultStat'
import TextFieldFormat from '../TextFieldFormat/TextFieldFormat'
import ButtonProgress from '../ButtonProgress/ButtonProgress'
import Util from '../../utils/Util'
import GroupContainer from '../GroupContainer/GroupContainer'

import {
    C_DEFAULTS,
} from '../../variables/common'

const styles = theme => ({
    root: {
        fontSize: '100%',
        padding: 0,
        margin: 0,
        width: '100%',
        overflowY: 'auto',
        height: '100%',
        backgroundColor: 'white',
    },
    margin: {
        margin: theme.spacing(1),
    },
    appBar: {
        top: 64,
        marginLeft: C_DEFAULTS.C_DRAWER_WIDTH
    },
    appBarToolbar: {
        marginLeft: C_DEFAULTS.C_DRAWER_WIDTH
    },
    toolbar: theme.mixins.toolbar,
    grow: {
        flexGrow: 1,
    },
    container: {
        padding: '10px 10px 0px 10px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    flexItemRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '5px 10px',
    },
    button: {
        margin: theme.spacing(1),
        textTransform: 'capitalize',
        '&.show-details-button': {
            margin:'10px 0 0 0',
        }
    },
    dateInputRoot: {
        width: 175,
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        flex: '1 1 auto'
    },
    inputLabel: {
        backgroundColor: 'white',
        paddingLeft: 3,
        paddingRight: 3,
    },
    chip: {
        margin: theme.spacing(1),
    },
    groupContainer: {
        width: 270,
        height: 45,
        border: '1px solid #c4c4c4',
        '&:hover': {
            border: '1px solid black'
        },
        '& .MuiFormControlLabel-label': {
            fontSize: '0.95em',
            fontWeight: 'normal',
        },
        '& .MuiSvgIcon-root': {
            fontSize: '1.3rem'
        }
    },
    checkBoxContainer: {
        width: 270,
        height: 180,
        border: '1px solid #c4c4c4',
        '&:hover': {
            border: '1px solid black'
        },
        '& .MuiFormControlLabel-label': {
            fontSize: '0.95em',
            fontWeight: 'normal',
        },
        '& .MuiSvgIcon-root': {
            fontSize: '1.3rem'
        }
    },
    checkBoxContainerSmall: {
        width: 155,
        height: 100,
        border: '1px solid #c4c4c4',
        '&:hover': {
            border: '1px solid black'
        },
        '& .MuiFormControlLabel-label': {
            fontSize: '0.95em',
            fontWeight: 'normal',
        },
        '& .MuiSvgIcon-root': {
            fontSize: '1.3rem'
        }
    }
})

const toAddDay = (dt, nDays) => moment.utc(dt).startOf('day').add(nDays, 'day').toDate()

class EventSearchForm extends _Base {

    constructor(props) {
        super(props)
        let { keywords, dateStart, dateEnd, detectedByMap, confidenceRatingMap, autoPublishMap, latitude, latitudeDelta, longitude, longitudeDelta, duration1, durationOperator, duration2 } = AppState.searchEventsPayload

        keywords = keywords || ''
        dateStart = dateStart || ''
        dateEnd = dateEnd || ''
        latitude = latitude || ''
        latitudeDelta = latitudeDelta || ''
        longitude = longitude || ''
        longitudeDelta = longitudeDelta || ''

        this.state = {
            keywords,
            dateStart,
            dateEnd,
            detectedByMap,
            confidenceRatingMap,
            autoPublishMap,
            latitude,
            latitudeDelta,
            longitude,
            longitudeDelta,
            duration1,
            durationOperator,
            duration2,
            events: AppState.publicEventList
        }
    }

    handleDetectedByCheckChange = name => event => {
        let { detectedByMap } = this.state
        switch (name){
            case 'GOES-EAST':
                detectedByMap['GLM-16'] = event.target.checked
                this.setState({
                        detectedByMap
                })
                break;
            case 'GOES-WEST':
                detectedByMap['GLM-17'] = event.target.checked
                detectedByMap['GLM-18'] = event.target.checked
                this.setState({
                    detectedByMap
                })
                break;
            default:
                detectedByMap[name] = event.target.checked
                this.setState({
                    detectedByMap
                })
        } 
    }
    handleConfidenceRatingCheckChange = name => event => {
        let { confidenceRatingMap } = this.state
        confidenceRatingMap[name] = event.target.checked
        this.setState(confidenceRatingMap)
    }

    handleAutoPublishMapChange = name => event => {
        let { autoPublishMap } = this.state;
        autoPublishMap[name] = event.target.checked;
        this.setState(autoPublishMap)
    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
        })
    }
    handleSearchClick = reset => (event, button) => {
        const { keywords, dateStart, dateEnd, detectedByMap, confidenceRatingMap, autoPublishMap, latitude, latitudeDelta, longitude, longitudeDelta, duration1, durationOperator, duration2 } = this.state
        let payload = {
            reset, keywords, dateStart, dateEnd, detectedByMap, confidenceRatingMap, autoPublishMap, latitude, latitudeDelta, longitude, longitudeDelta, duration1, durationOperator, duration2
        }

        const search = () => {
            payload = Util.assign({}, payload, {
                publicOnly: true,
                reset: false,
                advancedSearch: true
            })
            console.log('DEBUG: payload', event, button, payload)
            AppState.searchEvents(payload, (res) => {
                if (!res.success) {
                    console.log('DEBUG:error', res.error)
                }
                // button.reset()
                this.setState({
                    events: AppState.publicEventList
                }, () => {
                    Util.debounce2(() => { button.reset() }, 1000)
                })
            })
        }
        if (reset) {
            payload = { ...AppState.resetSearchEventsPayload, reset: false, advancedSearch: true }
            this.setState(payload, () => {
                search()
            })
        } else {
            search()
        }
    }
    handleDateRangeStartChange = (date) => {
        if (date + '' === 'Invalid Date') return

        const tmpDate = new Date(date) + ''
        if (tmpDate === 'Invalid Date') return

        const formattedDate = moment(tmpDate).format('MM/DD/YYYY');
        this.setState({
            dateStart: formattedDate
        })
    }
    handleDateRangeEndChange = (date) => {
        if (date + '' === 'Invalid Date') return

        const tmpDate = new Date(date) + ''
        if (tmpDate === 'Invalid Date') return

        const formattedDate = moment(tmpDate).format('MM/DD/YYYY');
        this.setState({
            dateEnd: formattedDate
        })
    }
    handleOnShowDetail = () => {
        this.props.history.push('/search')
    }
   
    render() {
        const { classes, location } = this.props
        const { keywords, dateStart, dateEnd, detectedByMap, confidenceRatingMap, autoPublishMap, latitude, latitudeDelta, longitude, longitudeDelta, duration1, durationOperator, duration2, events } = this.state

        const isSearch = keywords || latitude || latitudeDelta || longitude || longitudeDelta

        const minDate = AppState.eventMinDate 
        const maxDate = AppState.eventMaxDate 

        const dateConstraintMessageNode = (
            <span style={{ color: 'red', fontSize: '0.5rem' }}>
                Must be between<br />
                {`${dateStart} & ${dateEnd}`}
            </span>
        )
        const dateRange = [dateStart, dateEnd]
        const showMoreInfoButton = events.length > 0 && location.pathname !== '/search'

        return (
            <div className={classes.root}>
                <div style={{ padding: 5 }}>
                    <Typography variant='subtitle1' style={{ marginLeft: 10 }}>Find detections with...</Typography>

                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <div className={classes.flexItemRow}>
                            <DatePicker 
                                views={['year', 'month', 'day']}
                                inputVariant="outlined"
                                label="Start Date >="
                                format="MM/dd/yyyy"
                                minDate={dateRange[0] === dateRange[1] ? toAddDay(minDate, 0) : toAddDay(minDate, 1)}
                                maxDate={toAddDay(dateRange[1], 0)}
                                value={new Date(dateRange[0])}
                                onChange={this.handleDateRangeStartChange}
                                inputProps={{ 'aria-label': 'change date' }}
                                slotProps={{ textField: { className: classes.textField } }}
                            />

                            <DatePicker 
                                views={['year', 'month', 'day']}
                                inputVariant="outlined"
                                label="End Date <="
                                format="MM/dd/yyyy"
                                minDate={dateRange[0] === dateRange[1] ? toAddDay(dateRange[0], 0) : toAddDay(dateRange[0], 2)}
                                maxDate={dateRange[0] === dateRange[1] ? toAddDay(maxDate, 0) : toAddDay(maxDate, 1)}
                                value={new Date(dateRange[1])}
                                onChange={this.handleDateRangeEndChange}
                                inputProps={{ 'aria-label': 'change date' }}
                                slotProps={{ textField: { className: classes.textField } }}
                            />  
                        </div>
                    </LocalizationProvider>
                    <div className={classes.flexItemRow}>
                        <TextField
                            style={{ width: 100 }}
                            label="Latitude"
                            className={classes.textField}
                            value={latitude}
                            onChange={this.handleChange('latitude')}
                            margin="dense"
                            variant="outlined"
                            InputLabelProps={{
                                classes: {
                                    outlined: classes.inputLabel
                                }
                            }}
                            InputProps={{
                                inputProps: { maxLength: 6, decimalScale: 1 },
                                inputComponent: TextFieldFormat,
                            }}
                        />
                        <TextField
                            style={{ width: 100 }}
                            label="Δ&nbsp;Latitude"
                            className={classes.textField}
                            value={latitudeDelta}
                            onChange={this.handleChange('latitudeDelta')}
                            margin="dense"
                            variant="outlined"
                            InputLabelProps={{
                                classes: {
                                    outlined: classes.inputLabel
                                }
                            }}
                            InputProps={{
                                inputProps: { maxLength: 5, decimalScale: 1 },
                                inputComponent: TextFieldFormat,
                            }}
                        />
                    </div>

                    <div className={classes.flexItemRow}>
                        <TextField
                            style={{ width: 100 }}
                            label="Longitude"
                            className={classes.textField}
                            value={longitude}
                            onChange={this.handleChange('longitude')}
                            margin="dense"
                            variant="outlined"
                            InputLabelProps={{
                                classes: {
                                    outlined: classes.inputLabel
                                }
                            }}
                            InputProps={{
                                inputProps: { maxLength: 6, decimalScale: 1 },
                                inputComponent: TextFieldFormat,
                            }}
                        />

                        <TextField
                            style={{ width: 100 }}
                            label="Δ&nbsp;Longitude"
                            className={classes.textField}
                            value={longitudeDelta}
                            onChange={this.handleChange('longitudeDelta')}
                            margin="dense"
                            variant="outlined"
                            InputLabelProps={{
                                classes: {
                                    outlined: classes.inputLabel
                                }
                            }}
                            InputProps={{
                                inputProps: { maxLength: 5, decimalScale: 1 },
                                inputComponent: TextFieldFormat,
                            }}
                        />
                    </div>
                    
                    <div className={classes.flexItemRow}>
                        <GroupContainer style={{ flex: 'none', width: 240, marginTop: 8, marginLeft: 8, marginRight: 8, marginBottom: 4 }} className={classes.groupContainer} labelStyle={{ fontSize: '0.75rem' }} label='Duration (seconds)'>
                            <div style={{ display: 'flex' }}>
                                <div style={{ flex: 'none', width: 65 }}>
                                    <TextField
                                        style={{ width: 65, margin: 0 }}
                                        label={null}
                                        value={duration1}
                                        onChange={this.handleChange('duration1')}
                                        margin="dense"
                                        variant='standard'
                                        InputProps={{
                                            inputProps: { maxLength: 7, decimalScale: 3 },
                                            inputComponent: TextFieldFormat,
                                        }}
                                    />
                                </div>
                                <div style={{ flex: 'none', width: 65, marginLeft:5, marginRight:15 }}>
                                    <select onChange={this.handleChange('durationOperator')} value={durationOperator} className="operator" style={{backgroundColor: 'whitesmoke', border: 'none', height: 30}}>
                                        <option value='>'>&gt;</option>
                                        <option value='>='>&gt;&#61;</option>
                                        <option value='<'>&lt;</option>
                                        <option value='<='>&lt;&#61;</option>
                                        <option value='range'>Range</option>
                                    </select>
                                </div>
                                {durationOperator === 'range' &&
                                    <div style={{ flex: 'none', width: 65 }}>
                                        <TextField
                                            style={{ width: 65, margin: 0 }}
                                            label={null}
                                            value={duration2}
                                            onChange={this.handleChange('duration2')}
                                            margin="dense"
                                            variant='standard'
                                            InputProps={{
                                                inputProps: { maxLength: 7, decimalScale: 3 },
                                                inputComponent: TextFieldFormat,
                                            }}
                                        />
                                    </div>
                                }
                            </div>
                        </GroupContainer>

                        <TextField
                            className={classes.textField}
                            label="any of these key words:"
                            value={keywords}
                            onChange={this.handleChange('keywords')}
                            margin="dense"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                                classes: {
                                    outlined: classes.inputLabel
                                }
                            }}
                        />
                    </div>

                    <div className={classes.flexItemRow}>
                        <GroupContainer className={classes.checkBoxContainer} labelStyle={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '0.75em' }} label='Detected By'>        
                                <FormGroup style={{ flexDirection: 'row', justifyContent: 'space-between', padding:'10px 0px'}}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox style={{ padding: '0px 5px', color: '#757575' }} checked={detectedByMap['GLM-16']} onChange={this.handleDetectedByCheckChange('GOES-EAST')} value="GOES-EAST" />
                                        }
                                        label="GOES-EAST"
                                        style={{ paddingLeft: 5, fontSize: '0.85rem', fontWeight: 'normal' }}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox style={{ padding: '0px 5px', color: '#757575' }} checked={detectedByMap['Stereo']} onChange={this.handleDetectedByCheckChange('Stereo')} value="Stereo" />
                                        }
                                        label="Stereo"
                                        style={{ paddingLeft: 5, fontSize: '0.85rem', fontWeight: 'normal' }}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox style={{ padding: '0px 5px', color: '#757575' }} checked={detectedByMap['GLM-17']&&detectedByMap['GLM-18']} onChange={this.handleDetectedByCheckChange('GOES-WEST')} value="GOES-WEST" />
                                        }
                                        label="GOES-WEST"
                                        style={{ paddingLeft: 5, fontSize: '0.85rem', fontWeight: 'normal' }}
                                    />
                                </FormGroup>
                                <Divider variant="middle" />
                                <FormGroup style={{ flexDirection: 'row', justifyContent: 'space-between', padding:'10px 0px'}}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox style={{ padding: '0px 5px', color: '#757575' }} checked={detectedByMap['GLM-16']} onChange={this.handleDetectedByCheckChange('GLM-16')} value="GLM-16" />
                                        }
                                        label="GLM-16"
                                        style={{ paddingLeft: 5, fontSize: '0.85rem', fontWeight: 'normal' }}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox style={{ padding: '0px 5px', color: '#757575' }} checked={detectedByMap['GLM-17']} onChange={this.handleDetectedByCheckChange('GLM-17')} value="GLM-17" />
                                        }
                                        label="GLM-17"
                                        style={{ paddingLeft: 5, fontSize: '0.85rem', fontWeight: 'normal' }}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox style={{ padding: '0px 5px', color: '#757575' }} checked={detectedByMap['GLM-18']} onChange={this.handleDetectedByCheckChange('GLM-18')} value="GLM-18" />
                                        }
                                        label="GLM-18"
                                        style={{ paddingLeft: 5, fontSize: '0.85rem', fontWeight: 'normal' }}
                                    />
                                </FormGroup>
                        </GroupContainer>
                        <GroupContainer className={classes.checkBoxContainerSmall} sx={{height: 145}} labelStyle={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '0.75em' }} label='Confidence Rating'>
                            <div style={{ display: 'flex', justifyContent: 'space-between'  }}>
                                <FormGroup style={{ flexDirection: 'column' }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox style={{ padding: '0px 5px', color: '#757575' }} checked={confidenceRatingMap['low']} onChange={this.handleConfidenceRatingCheckChange('low')} value="low" />
                                        }
                                        label="Low"
                                        style={{ paddingLeft: 5, fontSize: '0.85rem', fontWeight: 'normal' }}
                                    />
                                    
                                    <FormControlLabel
                                        control={
                                            <Checkbox style={{ padding: '0px 5px', color: '#757575' }} checked={confidenceRatingMap['high']} onChange={this.handleConfidenceRatingCheckChange('high')} value="high" />
                                        }
                                        label="High"
                                        style={{ paddingLeft: 5, fontSize: '0.85rem', fontWeight: 'normal' }}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox style={{ padding: '0px 5px', color: '#757575' }} checked={confidenceRatingMap['medium']} onChange={this.handleConfidenceRatingCheckChange('medium')} value="medium" />
                                        }
                                        label="Medium"
                                        style={{ paddingLeft: 5, fontSize: '0.85rem', fontWeight: 'normal' }}
                                    />
                                </FormGroup>
                            </div>
                        </GroupContainer>
                        <GroupContainer className={classes.checkBoxContainerSmall} labelStyle={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '0.75em' }} label='Assessed By'>
                            <div style={{ display: 'flex', justifyContent: 'space-between'  }}>
                                <FormGroup style={{ flexDirection: 'column' }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox style={{ padding: '0px 5px', color: '#757575' }} checked={autoPublishMap['human']} onChange={this.handleAutoPublishMapChange('human')} value="low" />
                                        }
                                        label="Human"
                                        style={{ paddingLeft: 5, fontSize: '0.85rem', fontWeight: 'normal' }}
                                    />
                                    
                                    <FormControlLabel
                                        control={
                                            <Checkbox style={{ padding: '0px 5px', color: '#757575' }} checked={autoPublishMap['machine']} onChange={this.handleConfidenceRatingCheckChange('machine')} value="high" />
                                        }
                                        label="Machine"
                                        style={{ paddingLeft: 5, fontSize: '0.85rem', fontWeight: 'normal' }}
                                    />
                                </FormGroup>
                            </div>
                        </GroupContainer>
                    </div>
                    
                    
                    <div className={classes.flexItemRow} style={{ justifyContent: 'flex-end', marginRight: 10 }}>
                        <ButtonProgress
                            className={classes.button}
                            variant='outlined'
                            disabled={!isSearch}
                            onClick={this.handleSearchClick(true)}
                            keepColor color="primary"
                            style={{ marginRight: 10, width: 120 }}
                            startIcon={<ClearIcon />}>Reset</ButtonProgress>
                        <ButtonProgress
                            className={classes.button}
                            variant='contained'
                            disabled={!isSearch}
                            onClick={this.handleSearchClick(false)}
                            keepColor color="primary"
                            style={{ width: 120 }}
                            startIcon={<SendIcon />}>Search</ButtonProgress>
                    </div>
                </div>
                <Divider />
                <div style={{ padding: '25px 20px 5px' }}>
                    <EventSearchResultStat events={events} />

                    {showMoreInfoButton &&
                        <Button
                            onClick={this.handleOnShowDetail}
                            variant="outlined"
                            color="primary"
                            className={classNames(classes.button, 'show-details-button')}
                            startIcon={<ArtTrackIcon />}
                        >Show more info...</Button>
                    }
                    
                </div>
            </div>
        )
    }
}

EventSearchForm.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(EventSearchForm)
