import React from 'react'

// @mui/material components
import PropTypes from 'prop-types';
// import GridContainer from "../components/Grid/GridContainer.jsx"
import { withStyles } from '@mui/styles'
import Drawer from '@mui/material/Drawer'
import classNames from 'classnames'

import BaseComponent from '../components/_Base'
import Splash from '../components/Splash/Splash'
import MainAppBar from '../components/Main/MainAppBar'
import withSharedFeatures from './withSharedFeatures'
import AppState from '../managers/AppStateManager'
// jss
import { root, container, displayNone } from '../assets/jss/index'

import EventList from '../components/EventList/EventList'
import EventFormEntry from '../components/EventFormEntry/EventFormEntry'

import { C_EVENT_IDS, C_DEFAULTS, C_MODULE_NAME, C_ROLE } from '../variables/common'

const styles = (theme) => ({
  root: {
    ...root,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  },
  container: {
    ...container,
    margin: 0,
    paddingRight: 0,
    paddingLeft: 0,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    "& div.app-bar": {
      flex: 'none',
    },
    "& div.content": {
      marginLeft: C_DEFAULTS.C_DRAWER_WIDTH,
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  },
  gridContainer: {
    flexGrow: 1,
    height: "100%"
  },
  formContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formLineEntry: {

  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
    width: 100,
  },
  displayNone: {
    ...displayNone
  },
  drawer: {
    width: C_DEFAULTS.C_DRAWER_WIDTH,
    flexShrink: 0,
    zIndex: theme.zIndex.drawer - 2
  },
  drawerPaper: {
    width: C_DEFAULTS.C_DRAWER_WIDTH,
  },
  toolbar: theme.mixins.toolbar,
})

class Reviewer extends BaseComponent {

  constructor(props) {
    super(props)
    // pattern of extending state from the base class
    const baseState = { ...this.state }
    // extend base state....
    this.state = {
      ...baseState,
      isReady: false,
      eventList: [],
      totalEvents: 0,
      pageNum: 1,
      pageSize: 50,
      filterOptions: [],
      sortOptions: {field: "datetime", order: "desc"},
      searchTerm: ""
    }
    AppState.currentModuleName = C_MODULE_NAME.C_DATA_VERIFICATION
    AppState.filterOptions = []

  }

  handleEventListDataChanged = (data) => {
    this.setState({
      eventList: data
    })
  }

  handlePageSearchSortFilter = (pageNum, pageSize, filterOptions, sortOptions, searchTerm) => {
    this.setState({
      pageNum: pageNum,
      pageSize: pageSize,
      filterOptions: filterOptions,
      sortOptions: sortOptions,
      searchTerm: searchTerm
    });
    AppState.getReviewerEvents(pageNum, pageSize, filterOptions, sortOptions, searchTerm, (res) => {
      if (res.success) {
        this.setState({
          totalEvents: res.total,
        })
      }
    })
  }

  componentWillMount() {
    this.props.changeTheme('reviewerTheme')
  }

  componentDidMount() {
    super.componentDidMount()

    // AppState.addEventListener(C_EVENT_IDS.C_ON_EVENT_LIST_DATA_CHANGED, this.handleEventListDataChanged)

    AppState.preInit((error, data) => {

      if (error) {
        return console.log('DEBUG:error', error)
      }
      AppState.checkSession((err, session) => {
        if (!AppState.currentUser ||
          (AppState.selectedRole && AppState.selectedRole.toUpperCase() !== C_ROLE.C_REVIEWER)) {
          window.location.href = '/admin'
          return
        }

        AppState.getReviewerEvents(1, 50, [], {field: "datetime", order: "desc"}, "", (res) => {
          if (res.success) {
            const events = res.data
            const total = parseInt(res.total);
            const setState = () => {
              this.setState({
                isReady: true,
                totalEvents: total
              })
            }
            // if there are records, get event detail on the first record....
            if (events.length > 0) {
              const eventId = events[0]._id
              AppState.getEvent(eventId, (res) => {
                if (res.success) {
                  // const event = res.data
                  // AppState.updateEventList(event)
                  setState()
                } else {
                  console.log('DEBUG:error', res.error)
                }
              })
            } else {
              setState()
            }

          } else {
            console.log('DEBUG:error', res.error)
          }
        })
      })
    })


  }

  componentWillUnmount() {
    // AppState.removeEventListener(C_EVENT_IDS.C_ON_EVENT_LIST_DATA_CHANGED, this.handleEventListDataChanged)
  }

  render() {
    const { classes, ...rest } = this.props
    const { isReady, eventList, totalEvents, pageNum, pageSize, filterOptions, sortOptions, searchTerm } = this.state

    return (
      <div className={classNames('full-screen', classes.root)}>
          <div className={classes.container}>
            <div className='app-bar'>
              <MainAppBar {...rest} />
            </div>
            <Drawer
              className={classes.drawer}
              variant="permanent"
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              <EventList handleSearchSortFilter={this.handlePageSearchSortFilter} total={totalEvents} />

            </Drawer>

            <div className='content'>
              {!isReady && (
                <Splash showLogo={false} />
              )}
              {isReady && (
                <EventFormEntry {...rest} pageNum={pageNum} pageSize={pageSize} filterOptions={filterOptions} sortOptions={sortOptions} searchTerm={searchTerm} /> 
              )}
            </div>
          </div>
      </div>
    )
  }
}

Reviewer.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withSharedFeatures(withStyles(styles)(Reviewer))
