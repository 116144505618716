import React from 'react'

// @mui/material components
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import TextField from '@mui/material/TextField'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Chip from '@mui/material/Chip'
import ArrowForward from '@mui/icons-material/ArrowForwardIos'
import classNames from 'classnames'
import moment from 'moment'
import BaseComponent from '../_Base'
import withSharedFeatures from '../../views/withSharedFeatures'
import ButtonProgress from '../ButtonProgress/ButtonProgress'
import EventDetailForm from '../../components/Public/EventDetailForm'
import AppState from '../../managers/AppStateManager'
import GroupContainer from '../../components/GroupContainer/GroupContainer'

import {
	C_EVENT_STATUS,
	C_STATUS_MAP_ICON
} from '../../variables/common'

// jss
import { root, container } from '../../assets/jss/index'
import { Typography } from '@mui/material';


const styles = (theme) => ({
	root: {
		...root,
		fontSize: '0.85rem',
		width: 960,
	},
	container: {
		...container,
		display: 'flex',
		flexDirection: 'column',
	},
	titleText: {
		fontSize: '1rem',
		marginBottom: 15,
		marginTop: 25,
	},
	flexItemRow: {
		display: 'flex',
		alignItems: 'center',
		padding: 15,
	},
	eventStatusPanel: {
		background: 'linear-gradient(to bottom, #dcdcdc 0%,#ffffff 100%)'
	},
	buttonProgress: {
		position: 'relative'
	},
	button: {
		margin: '0px 8px',
		padding: 10,
		position: 'relative',
		top: 1,
		minWidth: 80,
	},
	initialStatus: {
		color: '#757575'
	},
	submittedStatus: {
		color: 'blue'
	},
	rejectedStatus: {
		color: 'red'
	},
	publishedStatus: {
		color: 'green'
	},
})

class EventSatus extends BaseComponent {

	handleSelectionChange = event => {
		this.setState({ [event.target.name]: event.target.value })
	}

	handleChange = name => event => {
		this.setState({
			[name]: event.target.value,
		})
	}

	handleOnClickButtonClear = (event) => {
		this.setState({
			eventId: '',
			event: null,
			selectedEventStatus: ''
		})
	}

	handleOnClickButtonUpdateEvent = (e, button) => {
		const { event, selectedEventStatus } = this.state
		const payload = {
			id: event._id,
			newstatus: selectedEventStatus,
		}
		const oldEventStatus = event.status
		AppState.changeEventStatus(payload, (res) => {
			let event = null
			if (res.success) {
				event = res.data
			}
			this.setState({
				event: event,
				selectedEventStatus: event ? event.status : ''
			}, () => {
				button.reset()
				if (res.success) {
					this.showMessage(`The event's status has been updated from '${oldEventStatus}' to '${event.status}'.`, 'info', true)
				} else {
					this.showMessage(`The was an error while updating the event's status! [Event Id: ${payload.id}]`, 'error', true)
				}
			})
		})
	}

	handleOnClickButtonCancel = (e) => {
		const { event } = this.state
		this.setState({
			selectedEventStatus: event.status
		})
	}

	handleOnClickButtonLoad = (event, button) => {
		const { eventId } = this.state
		AppState.getEvent(eventId, (res) => {
			let event = null
			if (res.success) {
				event = res.data
			}
			this.setState({
				event: event,
				selectedEventStatus: event ? event.status : ''
			}, () => {
				button.reset()
				if (!res.success) {
					this.showMessage(`The event does not exist! [Event Id: ${eventId}]`, 'error', true)
				}
			})
		})
	}

	reload = (cb) => {
		const { eventId } = this.state
		AppState.getEvent(eventId, (res) => {
			let event = null
			if (res.success) {
				event = res.data
			}
			this.setState({
				event: event,
				selectedEventStatus: event ? event.status : ''
			}, () => {
				cb && cb()
			})
		})
	}

	render() {
		const { classes, ...rest } = this.props
		let { eventId, event, selectedEventStatus } = this.state
		eventId = eventId ? eventId.trim() : ''
		const buttonDisabledEvent = event ? (event.status === selectedEventStatus ? true : false) : false
		const buttonDisabled = event ? event._id === eventId : !eventId

		let eventStatuses = [C_EVENT_STATUS.C_INITIAL, C_EVENT_STATUS.C_SUBMITTED, C_EVENT_STATUS.C_REJECTED, C_EVENT_STATUS.C_PUBLISHED]
		const eventFieldStatusMap = {
			[C_EVENT_STATUS.C_INITIAL]: { status: C_EVENT_STATUS.C_INITIAL, user: 'createdBy', date: 'createdDate' },
			// [C_EVENT_STATUS.C_ASSESSED]: { status: C_EVENT_STATUS.C_ASSESSED, user: 'assessedBy', date: 'assessedDate'},
			[C_EVENT_STATUS.C_SUBMITTED]: { status: C_EVENT_STATUS.C_SUBMITTED, user: 'submittedBy', date: 'submittedDate' },
			[C_EVENT_STATUS.C_REJECTED]: { status: C_EVENT_STATUS.C_REJECTED, user: 'rejectedBy', date: 'rejectedDate' },
			[C_EVENT_STATUS.C_PUBLISHED]: { status: C_EVENT_STATUS.C_PUBLISHED, user: 'publishedBy', date: 'publishedDate' },
		}
		
		
		let selectList;
		if (event) {
			if (!event.hasOwnProperty('rejectedBy')) {
				eventStatuses.splice(eventStatuses.indexOf(C_EVENT_STATUS.C_REJECTED), 1)
			}
			if (event.publishedBy === 'AUTO' && event.status === C_EVENT_STATUS.C_PUBLISHED) {
				selectList = [C_EVENT_STATUS.C_REJECTED, C_EVENT_STATUS.C_PUBLISHED];
			} else {
				selectList = eventStatuses;
			}
		}
		let eventWorkflow = []
		eventStatuses.forEach(es => {
			const f = eventFieldStatusMap[es]
			eventWorkflow.push({
				status: f.status,
				user: event && event[f.user] ? event[f.user] : '',
				date: event && event[f.date] ? moment.utc(event[f.date]).format('MM/DD/YYYY HH:mm:ss') : ''
			})
			eventWorkflow.push({
				status: 'ARROW'
			})
		})
		if (eventWorkflow[eventWorkflow.length - 1].status === 'ARROW') {
			eventWorkflow.pop()
		}

		return (
			<div className={classes.root}>
				<div className={classes.container}>
					<Typography className={classes.titleText} >Event Viewer</Typography>
					<div className={classes.flexItemRow} style={{ padding: '0px 0px 10px 0px' }}>
						<TextField
							autoFocus={true}
							style={{ flex: 'none', width: 300 }}
							label="Event ID"
							className={classes.textField}
							value={eventId}
							onChange={this.handleChange('eventId')}
							margin="dense"
							variant="outlined"
							InputLabelProps={{
								classes: {
									outlined: classes.inputLabel
								}
							}}
							InputProps={{
								inputProps: { maxLength: 24 }
							}}
						/>
						<ButtonProgress variant="outlined" classNameRoot={classes.buttonProgress} className={classes.button} disabled={buttonDisabled} onClick={this.handleOnClickButtonLoad} style={{ marginRight: 2 }}>Load</ButtonProgress>
						<Button variant="outlined" className={classes.button} onClick={this.handleOnClickButtonClear} style={{ marginLeft: 2 }}>Clear</Button>
					</div>

					{event &&
						<div>
							<Divider />
							<div className={classNames(classes.flexItemRow, classes.eventStatusPanel)} style={{ padding: '15px 0px 10px 10px', justifyContent: 'flex-start', backgroundColor: 'white' }}>
								<GroupContainer label='Current Status' labelStyle={{ backgroundColor: '#e4e4e4' }}>
									<Select
										variant="outlined"
										style={{ width: 150, padding: 0 }}
										value={selectedEventStatus}
										onChange={this.handleSelectionChange}
										inputProps={{
											name: 'selectedEventStatus',
										}}>
										{selectList.map((s) => {
											return(
												<MenuItem value={s}>{s}</MenuItem>
											)
										})}
									</Select>
								</GroupContainer>
								<ButtonProgress variant="outlined" classNameRoot={classes.buttonProgress} className={classes.button} style={{ marginLeft: 0, marginRight: 2 }} disabled={buttonDisabledEvent} onClick={this.handleOnClickButtonUpdateEvent}>Update</ButtonProgress>
								<Button variant="outlined" className={classes.button} style={{ marginLeft: 2 }} disabled={buttonDisabledEvent} onClick={this.handleOnClickButtonCancel}>Cancel</Button>

							</div>
							<div className={classes.flexItemRow} style={{ padding: '15px 10px 10px 10px', justifyContent: 'space-between', backgroundColor: 'white' }}>
								<GroupContainer label='Event Workflow' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', width: '100%' }}>

									{eventWorkflow.map((prop, key) => {
										let eventStatus = prop.status
										const eventStatusIcon = eventStatus && C_STATUS_MAP_ICON[eventStatus] ? C_STATUS_MAP_ICON[eventStatus].icon : null
										const eventStatusStyleName = eventStatus.toLowerCase() + 'Status'
										const isArrow = prop.status === 'ARROW'
										const contStyle = (event.status.toLowerCase() === eventStatus.toLowerCase()) ? { padding: '0px 10px 0px 5px' } : { padding: '0px 10px 0px 5px', opacity: 0.35 }
										return (
											<div key={key}>
												{isArrow && (
													<div style={{ flex: 'none', width: 35, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
														<ArrowForward style={{ color: 'darkgray' }} />
													</div>
												)}
												{!isArrow && (
													<div style={contStyle}>
														<Chip
															variant="outlined"
															icon={eventStatusIcon}
															label={eventStatus}
															style={{ marginLeft: -10 }}
															className={classes.chip}
															classes={{ icon: classes[eventStatusStyleName], label: classes[eventStatusStyleName] }}
														/>
														<div style={{ fontSize: '0.7em' }}>user:&nbsp;<span style={{ fontStyle: 'italic', fontWeight: 'bold' }}>{prop.user}</span></div>
														<div style={{ fontSize: '0.5em' }}>date:&nbsp;<span style={{ fontStyle: 'italic' }}>{prop.date}</span></div>
													</div>
												)}
											</div>
										)
									})}
								</GroupContainer>
							</div>
							<EventDetailForm event={event} onEventStatusRefresh={this.reload} layout="admin" allowRegenerate={true} {...rest} />
						</div>
					}

				</div>
			</div>
		);
	}
}

EventSatus.propTypes = {
	classes: PropTypes.object.isRequired
}

export default withSharedFeatures(withStyles(styles)(EventSatus))
