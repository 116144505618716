import React from 'react'
import Linkify from "linkify-react";
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'
import { Typography } from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel'
import moment from 'moment'
import EventFormAttachments from '../EventFormAttachments/EventFormAttachments'
import { C_DEFAULTS } from '../../variables/common'

const styles = theme => ({
    root: {
        fontSize: '100%',
        padding: 0,
        margin: 0,
        width: '100%',
        overflowY: 'auto',
        height: '100vh',
        backgroundColor: 'white',
    },
    margin: {
        margin: theme.spacing(1),
    },
    toolbar: theme.mixins.toolbar,
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    topPanel: {
        backgroundColor: '#232323',
        padding: '10px 15px',
        position: 'fixed',
        width: '100%',
        zIndex: 9,
    },
    flexItemRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        flexWrap: 'wrap',
    },
    formControlLabel: {
        whiteSpace: 'nowrap',
        margin: 0,
        cursor: 'default',
        '& p': {
            paddingLeft: 5,
            fontWeight: 'bold',
            color: '#cccccc',
        },
        '& span': {
            color: '#888888',
            fontStyle: 'italic',
        }
    },
    label: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '0.8rem',
    }
})

const EventDetailForm = (props) => {
    const { classes, event, layout, allowRegenerate, onEventStatusRefresh, ...rest } = props
    const eventAttachments = event.attachments
    const options = { defaultProtocol: 'https', target: "_blank"};
    // console.log(event);
    let { _id, 
        name, 
        datetime, 
        description, 
        detectedBy,
        howFound,
        otherDetectingSources,
        confidenceRating,
        otherInformation,
        duration,
        latitude, 
        longitude, 
        latitudeDelta, 
        longitudeDelta, 
        isManuallyGenerated,
        publishedBy,
        assessmentScore } = event

    // const dt = moment.utc(datetime)
    const getFormData = () => {
        return {
            eventId: _id,
            datetime: datetime,
            // eventDate: dt.format('MM/DD/YYYY'),
            // eventTime: dt.format('HH:mm:ss'),
            eventName: name,
            description: description,
            duration: duration,
            latitude: latitude,
            longitude: longitude,
            latitudeDelta: latitudeDelta,
            longitudeDelta: longitudeDelta,
            isManuallyGenerated: isManuallyGenerated
        }
    }

    detectedBy = detectedBy || '-'
    howFound = howFound || {}
    otherDetectingSources = otherDetectingSources || '-'
    confidenceRating = confidenceRating === "auto" ? "-" : confidenceRating || '-'
    publishedBy = confidenceRating && confidenceRating !== "-" ? 'Human' : 'auto'

    const titleLabel = moment.utc(datetime).format(C_DEFAULTS.C_DATE_FORMAT_STRING) + (name ? ' - ' + name : '')

    let localRoot = {}
    let localTopPanel = {}
    if (layout === 'admin') {
        localRoot = {
            overflowY: 'initial',
            height: 'initial',
        }
        localTopPanel = {
            position: 'initial'
        }
    }
    return (
        <div className={classes.root} style={localRoot}>
            {layout === 'public' && <div className={classes.toolbar} />}
            <div className={classes.container}>
                <div className={classes.topPanel} style={localTopPanel}>
                    <div className={classes.flexItemRow}>
                        <FormControlLabel
                            style={{ flex: 1, flexFlow: 'row' }}
                            className={classes.formControlLabel}
                            control={<Typography>{titleLabel}</Typography>}
                            label=""
                            labelPlacement="start"
                        />
                        <div style={{ flex: 2 }} className={classes.flexItemRow}>
                            {/* <FormControlLabel
                                className={classes.formControlLabel}
                                control={<Typography>{duration}</Typography>}
                                label="Duration:"
                                labelPlacement="start"
                            /> */}
                            <FormControlLabel
                                className={classes.formControlLabel}
                                control={<Typography>{latitude.toFixed(1)}</Typography>}
                                label="Latitude:"
                                labelPlacement="start"
                            />
                            <FormControlLabel
                                className={classes.formControlLabel}
                                control={<Typography>{longitude.toFixed(1)}</Typography>}
                                label="Longitude:"
                                labelPlacement="start"
                            />
                            <FormControlLabel
                                className={classes.formControlLabel}
                                control={<Typography>{latitudeDelta.toFixed(1)}</Typography>}
                                label="Δ&nbsp;Latitude:"
                                labelPlacement="start"
                            />
                            <FormControlLabel
                                className={classes.formControlLabel}
                                control={<Typography>{longitudeDelta.toFixed(1)}</Typography>}
                                label="Δ&nbsp;Longitude:"
                                labelPlacement="start"
                            />
                        </div>
                    </div>
                </div>
                {layout === 'public' && <div style={{height: 41}}></div>}
                <div className={classes.flexItemRow} style={{padding: 5, justifyContent: 'space-between'}}>
                    <FormControlLabel
                        control={
                            <Typography>
                                {detectedBy}
                            </Typography>
                        }
                        className={classes.formControl}
                        classes={{ label: classes.label }}
                        style={{alignItems: 'initial'}}
                        label='Detected By'
                        labelPlacement='top'
                    />
                    {assessmentScore.assessment ?
                        <FormControlLabel
                            control={
                                <Typography>
                                    {`${assessmentScore.assessment} : ${assessmentScore.score.toFixed(4)}`}
                                </Typography>
                            }
                            className={classes.formControl}
                            classes={{ label: classes.label }}
                            style={{alignItems: 'initial'}}
                            label='Machine Assessment'
                            labelPlacement='top'
                        /> : null
                    }
                    <FormControlLabel
                        control={
                            <Typography>
                                {publishedBy === 'auto' ? "Machine" : "Human"}
                            </Typography>
                        }
                        className={classes.formControl}
                        classes={{ label: classes.label }}
                        style={{alignItems: 'initial'}}
                        label='Assessed By'
                        labelPlacement='top'
                    />
                    {
                        Object.keys(howFound).map(satName => {
                            return (
                                <FormControlLabel
                                    control={
                                        <Typography>
                                            {howFound[satName].join(", ")}
                                        </Typography>
                                    }
                                    className={classes.formControl}
                                    classes={{ label: classes.label }}
                                    label={`How Found: ${satName}`}
                                    labelPlacement='top'
                                />
                            )
                        })
                    }
                    <FormControlLabel
                        control={
                            <Typography>
                                {otherDetectingSources}
                            </Typography>
                        }
                        className={classes.formControl}
                        classes={{ label: classes.label }}
                        label='Other Detecting Sources'
                        labelPlacement='top'
                    />
                    <FormControlLabel
                        control={
                            <Typography>
                                {confidenceRating}
                            </Typography>
                        }
                        className={classes.formControl}
                        classes={{ label: classes.label }}
                        label='Human Confidence Rating'
                        labelPlacement='top'
                    />
                </div>
                {otherInformation &&
                    <div className={classes.flexItemRow} style={{borderTop: '1px solid #e0e0e0', paddingLeft: 12, paddingRight: 12}}>
                        <div style={{ width: '100%', overflow: 'auto', padding: 8 }}>
                            <div style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '0.8rem', marginBottom: 5 }}>Other Information</div>
                            <Typography>
                                <Linkify as="span" options={options}>
                                    {otherInformation}
                                </Linkify>
                            </Typography>
                        </div>
                    </div>
                }
                {description &&
                    <div className={classes.flexItemRow} style={{borderTop: '1px solid #e0e0e0', paddingLeft: 12, paddingRight: 12}}>
                        <div style={{ width: '100%', overflow: 'auto', padding: 8 }}>
                            <div style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '0.8rem', marginBottom: 5 }}>Description</div>
                            <Typography>
                                <Linkify as="span" options={options}>
                                    {description}
                                </Linkify>
                            </Typography>
                        </div>
                    </div>
                }
                <div style={{padding: '0px 10px'}}>
                    <EventFormAttachments showDownload={true} showTitle={false} event={event} eventAttachments={eventAttachments} getFormData={getFormData} allowRegenerate={allowRegenerate} onEventStatusRefresh={onEventStatusRefresh} status={event.status} isManuallyGenerated={isManuallyGenerated} {...rest} />
                </div>
                <div>&nbsp;</div>
            </div>
        </div>
    )
}


EventDetailForm.propTypes = {
    classes: PropTypes.object.isRequired,
}

EventDetailForm.defaultProps = {
    layout: 'public'
}
export default withStyles(styles)(EventDetailForm)
