import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'
import { styled } from '@mui/material/styles';
import List from '@mui/material/List'
import Typography from '@mui/material/Typography'
import ListItemButton from '@mui/material/ListItemButton'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import TextField from '@mui/material/TextField'
import Drawer from '@mui/material/Drawer';
import classNames from 'classnames'
import Tooltip from '@mui/material/Tooltip'
import Fab from '@mui/material/Fab'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
import ListItemIcon from '@mui/material/ListItemIcon' 
import AddIcon from '@mui/icons-material/Add'
import LensIcon from '@mui/icons-material/Lens';
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import Avatar from '@mui/material/Avatar'
import Pagination from '@mui/material/Pagination';
import moment from 'moment'

import _Base from '../_Base'
import AppState from '../../managers/AppStateManager'
import { C_EVENT_IDS, C_MODULE_NAME, C_STATUS_MAP_ICON, C_DEFAULTS, C_EVENT_SCORES, C_EVENT_SCORES_COLORS, C_EVENT_STATUS } from '../../variables/common'
import Util from '../../utils/Util'
import { FormControl, FormControlLabel, FormLabel, Checkbox, FormGroup, ListItemAvatar, IconButton } from '@mui/material'


const styles = theme => ({
    root: {
        fontSize: '100%',
        position: "relative !important",
        "& .MuiBackdrop-root": {
            position: "relative !important",
        }
    },
    paper: {
        position: "relative !important"
    },
    topPanel: {
        backgroundColor: 'white',
        position: 'fixed',
        height: 'auto',
        width: C_DEFAULTS.C_DRAWER_WIDTH,
        zIndex: 1,
    },
    topPanelSpacer: {
        height: 32,
    },
    flexItemRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: '8px'
    },
    toolTip: {
        backgroundColor: '#fec32d',
        color: 'black',
        padding: 10,
        fontSize: '1.2em',
        opacity: 1,
    },
    button: {
        margin: theme.spacing(1),
    },
    margin: {
        margin: theme.spacing(1),
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        flex: 1,
    },
    dense: {
        marginTop: 16,
    },
    toolbar: theme.mixins.toolbar,
    selectEmpty: {
        width: '100%',
        marginTop: theme.spacing(2),
        marginRight: 10,
        marginLeft: 10,
        marginBottom: 10,
    },
    listItemText: {
        paddingLeft: 0,
    },
    listItemPrimaryText: {
        fontWeight: 500,
    },
    listItemSecondaryText: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        fontWeight: 300,
        color: 'black'
    },
    filterSection: {
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: '6px',
        marginRight: '6px',
        fontSize: "10px"
    },
    lensIcon: {
        height: "10px", 
        width: "10px", 
        verticalAlign: 'top', 
        marginTop: "8px"
    },
    drawerSection: {
        zIndex: 3
    }
})

const sortOptionsMap = {
    0: { display: 'Sort Last Modified Date Ascending', value: { field: 'lastModifiedDate', order: 'asc' } },
    1: { display: 'Sort Last Modified Date Descending', value: { field: 'lastModifiedDate', order: 'desc' } },
    2: { display: 'Sort Event Date Ascending', value: { field: 'datetime', order: 'asc' } },
    3: { display: 'Sort Event Date Descending', value: { field: 'datetime', order: 'desc' } },
    5: { display: 'Sort Event Name Ascending', value: { field: 'name', order: 'asc' } },
    6: { display: 'Sort Event Name Descending', value: { field: 'name', order: 'desc' } },
    7: { display: 'Sort Event Status Ascending', value: { field: 'status', order: 'asc' } },
    8: { display: 'Sort Event Status Descending', value: { field: 'status', order: 'desc' } },
    9: { display: 'Sort Human Classification Ascending', value: { field: 'humanClassification', order: 'asc' } },
    10: { display: 'Sort Human Classification Descending', value: { field: 'humanClassification', order: 'desc' } },
}

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
    height: "auto"
}));
class EventList extends _Base {

    handleOnSearchChange = event => {
        
        let searchText = event.target.value;
        this.setState({
            searchText: searchText
        })
    }

    handleOnSortChange = event => {
        const sortOpt = sortOptionsMap[event.target.value]
        this.setState({ [event.target.name]: event.target.value })// setting { sort: event.target.value } (which is a number 0 - 10)

        this.handleSearchSortFilter(this.state.pageNumber, this.state.limit, this.state.checkedFilters, sortOpt.value, this.state.searchText)
    }

    handleFilterChange = event => {
        const filterOptions = AppState.filterEventList(event.target.value, event.target.checked);
        this.setState({checkedFilters: filterOptions})

        this.handleSearchSortFilter(this.state.pageNumber, this.state.limit, filterOptions, sortOptionsMap[this.state.sort].value, this.state.searchText);

    }

    handleEnterSearch = event => {
        this.handleSearchSortFilter(this.state.pageNumber, this.state.limit, this.state.checkedFilters, sortOptionsMap[this.state.sort].value, this.state.searchText)
        this.setState({drawerOpen: !this.state.drawerOpen})
    }

    handleAddButtonClick = (e) => {
        AppState.setAddMode(true, () => {
            AppState.currentEventId = ''
            this.forceUpdate()
        })
    }

    

    handleListItemClick = eventId => (e) => {
        // console.log('DEBUG: handleListItemClick', eventId)
        AppState.getEvent(eventId, (res) => {
            if (res.success) {
                AppState.setViewMode(false, () => {
                    AppState.currentEventId = eventId
                    //this.forceUpdate()
                })
            } else {
                console.log('DEBUG:error', res.error)
            }
        })
    }

    handleEventListDataChanged = (data) => {
        this.state.eventList = Util.cloneDeep(AppState.eventList) 
        this.forceUpdate()
    }

    handleOnEventDataLoaded = (event) => {
        this.state.eventList = Util.cloneDeep(AppState.eventList)
        Util.debounce(() => {this.forceUpdate()}, 100)()
    }

    handleOnSetViewMode = () => {
        Util.debounce(() => {this.forceUpdate()}, 100)()
    }

    handleOnEventDeleted = (eventId) => {
        let eventList = this.state.eventList
        let idx = eventList.findIndex((e) => { return e._id === eventId})

        if (idx !== -1) {
            eventList.splice(idx, 1)
            AppState.eventList = eventList
            this.forceUpdate()
        }
    }

    handleExpandList = () => {
        this.setState({ limit: this.state.limit + 50})
    }

    handlePageChange = (event, value) => {
        this.setState({pageNumber: value})
        
        this.handleSearchSortFilter(value, this.state.limit, this.state.checkedFilters, sortOptionsMap[this.state.sort].value, this.state.searchText);
    }

    handleToggleDrawer = (event) => {
        this.setState({drawerOpen: !this.state.drawerOpen})
    }

    constructor(props) {
        super(props)
        // this.state.eventList = Util.cloneDeep(AppState.eventList)
        this.handleSearchSortFilter = this.props.handleSearchSortFilter;
        this.state = {
            show: false,
            sort: props.sort ? props.sort : 3,
            limit: 50,
            checkedFilters: [],
            drawerOpen: false,
            pageNumber: 1,
            total: props.total,
            searchText: "",
            eventList: Util.cloneDeep(AppState.eventList),
        }
    }

    componentDidMount() {
        super.componentDidMount()
        AppState.addEventListener(C_EVENT_IDS.C_ON_EVENT_LIST_DATA_CHANGED, this.handleEventListDataChanged)
        AppState.addEventListener(C_EVENT_IDS.C_ON_SET_VIEW_MODE, this.handleOnSetViewMode)
        AppState.addEventListener(C_EVENT_IDS.C_ON_EVENT_DELETED, this.handleOnEventDeleted)
        AppState.addEventListener(C_EVENT_IDS.C_ON_EVENT_DATA_LOADED, this.handleOnEventDataLoaded)
    }

    componentWillUnmount() {
        AppState.removeEventListener(C_EVENT_IDS.C_ON_EVENT_LIST_DATA_CHANGED, this.handleEventListDataChanged)
        AppState.removeEventListener(C_EVENT_IDS.C_ON_SET_VIEW_MODE, this.handleOnSetViewMode)
        AppState.removeEventListener(C_EVENT_IDS.C_ON_EVENT_DELETED, this.handleOnEventDeleted)
        AppState.removeEventListener(C_EVENT_IDS.C_ON_EVENT_DATA_LOADED, this.handleOnEventDataLoaded)
    }

    render() {
        const { classes, total } = this.props
        const { searchText, limit, drawerOpen } = this.state
        const eventList = this.state.eventList
        const noEvents = this._isReady && eventList.length === 0
        const withEvents = eventList.length > 0;
        const pageCount = total%limit === 0 ? total/limit : Math.floor(total/limit + 1)
        
        return (
            <div className={classes.root}>
                <div>
                    <div className={classes.toolbar} />
                    <div className={classes.topPanel}>
                        <div>
                            <Button
                                onClick={this.handleToggleDrawer}
                                aria-label='search-filter-menu-open'
                                aria-haspopup="true"
                            >
                                <MenuIcon/>
                            </Button>
                        </div>
                        <Drawer
                            anchor='left'
                            open={drawerOpen}
                            onClose={this.handleToggleDrawer}
                            sx={{
                                width: C_DEFAULTS.C_DRAWER_WIDTH,
                                flexShrink: 0,
                                '& .MuiDrawer-paper': {
                                    width: C_DEFAULTS.C_DRAWER_WIDTH,
                                    boxSizing: 'border-box',
                                    
                                },
                            }}
                        >
                            <DrawerHeader>
                                <IconButton onClick={this.handleToggleDrawer}>
                                    <ChevronLeftIcon />
                                </IconButton>
                            </DrawerHeader>
                            <div className={classes.flexItemRow}>
                                <TextField
                                    label="Press Enter to Search"
                                    className={classNames(classes.textField, classes.dense)}
                                    margin="dense"
                                    variant="outlined"
                                    defaultValue={searchText}
                                    onChange={this.handleOnSearchChange}
                                    onKeyDown={(ev) => {
                                        if (ev.key === 'Enter') {
                                            this.handleEnterSearch();
                                            ev.preventDefault();
                                        }
                                    }}
                                />
                                {AppState.currentModuleName === C_MODULE_NAME.C_DATA_ENTRY &&
                                    <Tooltip title="Create new event" classes={{ tooltip: classes.toolTip }}>
                                        <Fab size="small" color="primary" aria-label="Add" className={classes.margin} style={{ marginTop: 10, float: "right" }} onClick={this.handleAddButtonClick}>
                                            <AddIcon />
                                        </Fab>
                                    </Tooltip>
                                }
                            </div>

                            {withEvents &&
                                <div className={classes.flexItemRow}>
                                    <Select
                                        variant="outlined"
                                        value={this.state.sort}
                                        onChange={this.handleOnSortChange}
                                        displayEmpty
                                        name="sort"
                                        className={classes.selectEmpty}>
                                        {Object.keys(sortOptionsMap).map(key => (
                                            <MenuItem key={key} value={key}>{sortOptionsMap[key].display}</MenuItem>

                                        ))}
                                    </Select>
                                </div>
                            }
                            {/* Add filter radio group to filter the list*/}
                            <div className={classes.flexItemRow}>
                                <FormControl className='filterSection'>
                                    <FormLabel id="hypothesis-score-filter-label">Filter Score</FormLabel>
                                    <FormGroup row>
                                        <div>
                                            <FormControlLabel sx={{marginLeft: '4px', marginRight: '4px'}} value={C_EVENT_SCORES.ACCEPTED} control={<Checkbox checked={this.state.checkedFilters.includes(C_EVENT_SCORES.ACCEPTED)}/>} onChange={this.handleFilterChange} label="Accepted" labelPlacement='top'/>
                                            <LensIcon className={classes.lensIcon} sx={{color: C_EVENT_SCORES_COLORS[C_EVENT_SCORES.ACCEPTED].style.color}}/>
                                        </div>
                                        <div>
                                            <FormControlLabel sx={{marginLeft: '4px', marginRight: '4px'}} value={C_EVENT_SCORES.CANDIDATE} control={<Checkbox checked={this.state.checkedFilters.includes(C_EVENT_SCORES.CANDIDATE)}/>} onChange={this.handleFilterChange} label="Candidate" labelPlacement='top'/>
                                            <LensIcon className={classes.lensIcon} sx={{color: C_EVENT_SCORES_COLORS[C_EVENT_SCORES.CANDIDATE].style.color}}/>
                                        </div>
                                        <div>
                                            <FormControlLabel sx={{marginLeft: '4px', marginRight: '4px'}} value={C_EVENT_SCORES.REJECTED} control={<Checkbox checked={this.state.checkedFilters.includes(C_EVENT_SCORES.REJECTED)}/>} onChange={this.handleFilterChange} label="Rejected" labelPlacement='top'/>
                                            <LensIcon className={classes.lensIcon} sx={{color: C_EVENT_SCORES_COLORS[C_EVENT_SCORES.REJECTED].style.color}}/>
                                        </div>
                                        <div>
                                            <FormControlLabel sx={{marginLeft: '4px', marginRight: '4px'}} value={C_EVENT_SCORES.NA} control={<Checkbox checked={this.state.checkedFilters.includes(C_EVENT_SCORES.NA)}/>} onChange={this.handleFilterChange} label="N/A" labelPlacement='top'/>
                                            <LensIcon className={classes.lensIcon} sx={{color: C_EVENT_SCORES_COLORS[C_EVENT_SCORES.NA].style.color}}/>
                                        </div>
                                    </FormGroup>
                                </FormControl>
                            </div>
                        </Drawer>
                    </div>

                    <div className={classes.topPanelSpacer} />

                    {noEvents &&
                        <div>
                            <div className={classes.toolbar} />
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: 20 }}>
                                <Typography style={{ fontStyle: 'italic' }}>There are no events...</Typography>
                            </div>
                        </div>
                    }
                    {withEvents &&
                        <List component="nav">
                            {eventList.map(event => (
                                <ListItemButton
                                    key={event._id}
                                    selected={event._id === AppState.currentEventId}
                                    onClick={this.handleListItemClick(event._id)}
                                    sx={{
                                        "&.Mui-selected": {
                                            backgroundColor: event.assessmentScore.assessment ? C_EVENT_SCORES_COLORS[`${event.assessmentScore.assessment}`].style.color : '#D3D3D3'
                                        },
                                        "&:hover": {
                                            backgroundColor: event.assessmentScore.assessment ? C_EVENT_SCORES_COLORS[`${event.assessmentScore.assessment}`].style.color : '#D3D3D3'
                                        },
                                        "&.Mui-selected:hover": {
                                            backgroundColor: event.assessmentScore.assessment ? C_EVENT_SCORES_COLORS[`${event.assessmentScore.assessment}`].style.color : '#D3D3D3'
                                        }
                                    }}
                                >
                                    <ListItemIcon style={C_STATUS_MAP_ICON[event.status].style} title={event.status}>
                                        {C_STATUS_MAP_ICON[event.status].icon}
                                    </ListItemIcon>
                                    <ListItemText className={classes.listItemText}
                                        classes={{ primary: classes.listItemPrimaryText }}
                                        primary={moment.utc(event.datetime).format(C_DEFAULTS.C_DATE_FORMAT_STRING)}
                                        secondary={
                                            <React.Fragment>
                                                <Typography component="span" className={classes.listItemSecondaryText}>
                                                    {event.name}
                                                </Typography>
                                            </React.Fragment>
                                        } 
                                    />
                                    {event.assessmentScore.assessment === C_EVENT_SCORES.ACCEPTED && event.status === C_EVENT_STATUS.C_INITIAL && (
                                        <ListItemAvatar sx={{minWidth: "0px"}}>
                                            <Avatar sx={{height: "24px", width: "24px", color: "black"}}>
                                                <Typography sx={{fontSize: "10px"}}>{event.checkout_time}</Typography>
                                                <AccessAlarmIcon sx={{height: "10px", margin: "-6px"}}/>
                                            </Avatar>
                                        </ListItemAvatar>
                                    )}
                                </ListItemButton>
                            ))}
                            <ListItem
                                sx={{width: "1000%"}}
                            >
                                <Pagination count={isNaN(pageCount) ? 0 : pageCount} size='small' onChange={this.handlePageChange} showFirstButton showLastButton/>
                            </ListItem>
                        </List>
                    }
                </div>
            </div>
        );
    }
}

EventList.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(EventList)