import React from 'react';
import PlayListAddIcon from '@mui/icons-material/PlaylistAddOutlined';
import ThumbsDownIcon from '@mui/icons-material/ThumbDownAltOutlined';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import PublishIcon from '@mui/icons-material/Publish';

const C_EVENT_IDS = {
    C_ON_SET_MODULE_NAME: 'onSetModuleName',
    C_ON_CHANGE_LAYOUT: 'onChangeLayout',
    C_ON_TOGGLE_MAIN_DRAWER: 'onToggleMainDrawer',
    C_ON_OPEN_MAIN_DRAWER: 'onOpenMainDrawer',
    C_ON_CLOSE_MAIN_DRAWER: 'onCloseMainDrawer',
    C_ON_REMOVE_ATTACHMENT: 'onRemoveAttachment',
    C_ON_UPDATE_ATTACHMENT: 'onUpdateAttachment',
    C_ON_EVENT_LIST_DATA_CHANGED: 'onEventListDataChanged',
    C_ON_EVENT_DATA_LOADED: 'onEventDataLoaded',
    C_ON_EVENT_DELETED: 'onEventDeleted',
    C_ON_SET_ADD_MODE: 'onSetAddMode',
    C_ON_SET_EDIT_MODE: 'onSetViewMode',
    C_ON_SET_VIEW_MODE: 'onSetViewMode',
    C_ON_USER_LOGGED_IN: 'onUserLoggedIn',
    C_ON_USER_LOGGED_OUT: 'onUserLoggedOut',
    C_ON_USER_CHANGE_ROLE: 'onUserChangeRole',
    C_ON_SEARCH_EVENTS: 'onSearchEvents',
    C_ON_FILTER_CHANGED: 'onFilterChange',
    C_ON_SCREEN_MODE_CHANGED: 'onScreenModeChanged',
    C_ON_WINDOW_RESIZED: 'onWindowResized',
    C_ON_AJAX_REQUEST_STARTED: 'onAjaxRequestStarted',
    C_ON_AJAX_REQUEST_ENDED: 'onAjaxRequestEnded',
    C_ON_AJAX_REQUEST_ERROR: 'onAjaxRequestError',
}

const C_ROLE = {
    C_SME: 'SME',
    C_REVIEWER: 'REVIEWER',
    C_ADMIN: 'ADMIN',
}

const C_MODULE_NAME= {
    C_PUBLIC_PAGE: 'Public',
    C_SEARCH_PAGE: 'Search',
    C_DATA_ENTRY: 'GLM Data Entry',
    C_DATA_VERIFICATION: 'GLM Data Verification',
    C_ADMIN_SETTINGS: 'Settings'
}

const C_MODE = {
    C_ADD_MODE: 'ADD',
    C_EDIT_MODE: 'EDIT',
    C_VIEW_MODE: 'VIEW',
    C_DELETE_MODE: 'DELETE',
}

const C_EVENT_STATUS = {
    C_INITIAL: 'INITIAL',
    // C_ASSESSED: 'ASSESSED',
    C_SUBMITTED: 'SUBMITTED',
    C_REJECTED: 'REJECTED',
    C_PUBLISHED: 'PUBLISHED',
}

const C_EVENT_SCORES = {
    ACCEPTED: 'accepted',
    CANDIDATE: 'candidate',
    REJECTED: 'rejected',
    NA: 'N/A'
}

const C_EVENT_SCORES_COLORS = {
    [C_EVENT_SCORES.ACCEPTED]: {style: {color: '#43ff6499'}}, //rgba(67, 255, 100, 0.6)
    [C_EVENT_SCORES.CANDIDATE]: {style: {color: '#0248ff99'}}, //rgba(2, 72, 255, 0.6)
    [C_EVENT_SCORES.REJECTED]: {style: {color: '#f5272799'}}, //rgba(245, 39, 39, 0.6)
    [C_EVENT_SCORES.NA]: {style: {color: '#6f6f6f80'}} //rgba(111, 111, 111, 0.5)
}

const C_STATUS_MAP_ICON = {
    [C_EVENT_STATUS.C_INITIAL]: {icon: <PlayListAddIcon />, style: {color: '#757575', width: 30, minWidth: 30}},
    // [C_EVENT_STATUS.C_ASSESSED]: {icon: <PlaylistAddCheckIcon />, style:{color: '#41bfa0', width: 30, minWidth: 30}},
    [C_EVENT_STATUS.C_SUBMITTED]: {icon: <InboxIcon />, style: {color: 'blue', width: 30, minWidth: 30}},
    [C_EVENT_STATUS.C_REJECTED]: {icon: <ThumbsDownIcon />, style: {color: 'red', width: 30, minWidth: 30}},
    [C_EVENT_STATUS.C_PUBLISHED]: {icon: <PublishIcon />, style:{color: 'green', width: 30, minWidth: 30}},
}

const C_DEFAULTS = {
    C_DRAWER_WIDTH: 330, //255,
    C_DATE_FORMAT_STRING: 'MM/DD/YYYY HH:mm:ss.SSS'
}

const C_LEGEND_LABEL_MAP = {
    //Stereo Group
    "GLM-16,GLM-17,high": "Stereo High Confidence",
    "GLM-16,GLM-17,medium": "Stereo Medium Confidence",
    "GLM-16,GLM-17,low": "Stereo Low Confidence",
    "GLM-16,GLM-18,high": "Stereo High Confidence",
    "GLM-16,GLM-18,medium": "Stereo Medium Confidence",
    "GLM-16,GLM-18,low": "Stereo Low Confidence",
    "GLM-16,GLM-17,GLM-18,high": "Stereo High Confidence",
    "GLM-16,GLM-17,GLM-18,medium": "Stereo Medium Confidence",
    "GLM-16,GLM-17,GLM-18,low": "Stereo Low Confidence",
    "GLM-16,GLM-17,auto": "Stereo Auto-Publish",
    "GLM-16,GLM-18,auto": "Stereo Auto-Publish",
    "GLM-16,GLM-17,GLM-18,auto": "Stereo Auto-Publish",

    
    //GLM-17, GLM-18 Group
    "GLM-17,high": "GLM-17 High Confidence",
    "GLM-17,medium": "GLM-17 Medium Confidence",
    "GLM-17,low": "GLM-17 Low Confidence",
    "GLM-18,high": "GLM-18 High Confidence",
    "GLM-18,medium": "GLM-18 Medium Confidence",
    "GLM-18,low": "GLM-18 Low Confidence",
    "GLM-17,GLM-18,high": "GLM-18 High Confidence",
    "GLM-17,GLM-18,medium": "GLM-18 Medium Confidence",
    "GLM-17,GLM-18,low": "GLM-18 Low Confidence",
    "GLM-17,auto": "GLM-17 Auto-Publish",
    "GLM-18,auto": "GLM-18 Auto-Publish",
    "GLM-17,GLM-18,auto": "GLM-18 Auto-Publish",

    //GLM-16 Group
    "GLM-16,high": "GLM-16 High Confidence",
    "GLM-16,medium": "GLM-16 Medium Confidence",
    "GLM-16,low": "GLM-16 Low Confidence",
    "GLM-16,auto": "GLM-16 Auto-Publish",

}

const GLM16_COLOR = '213,94,0'
const GLM17_COLOR = '230,159,0'
const GLM18_COLOR = '230,159,0'
const STEREO_COLOR = '240,228,66'
const AUTO_COLOR = '54, 128, 2'

const C_GLM_COLOR = {
    //GLM-16 Group Color
    "GLM-16,high": {
        fill: `rgba(${GLM16_COLOR}, 0.95)`,
        stroke: `rgba(${GLM16_COLOR}, 1)`
    },
    "GLM-16,medium": {
        fill: `rgba(${GLM16_COLOR}, 0.7)`,
        stroke: `rgba(${GLM16_COLOR}, 1)`
    },
    "GLM-16,low": {
        fill: `rgba(${GLM16_COLOR}, 0.5)`,
        stroke: `rgba(${GLM16_COLOR}, 1)`
    },
    "GLM-16,auto": {
        fill: `rgba(${GLM16_COLOR}, 0.95)`,
        stroke: `rgba(${AUTO_COLOR}, 1)`
    },

    //GLM-17 & GLM-18 Group Color
    "GLM-17,high": {
        fill: `rgba(${GLM17_COLOR}, 0.95)`,
        stroke: `rgba(${GLM17_COLOR}, 1)`
    },
    "GLM-17,medium": {
        fill: `rgba(${GLM17_COLOR}, 0.7)`,
        stroke: `rgba(${GLM17_COLOR}, 1)`
    },
    "GLM-17,low": {
        fill: `rgba(${GLM17_COLOR}, 0.5)`,
        stroke: `rgba(${GLM17_COLOR}, 1)`
    },
    "GLM-17,auto": {
        fill: `rgba(${GLM17_COLOR}, 0.95)`,
        stroke: `rgba(${AUTO_COLOR}, 1)`
    },
    "GLM-18,high": {
        fill: `rgba(${GLM18_COLOR}, 0.95)`,
        stroke: `rgba(${GLM18_COLOR}, 1)`
    },
    "GLM-18,medium": {
        fill: `rgba(${GLM18_COLOR}, 0.7)`,
        stroke: `rgba(${GLM18_COLOR}, 1)`
    },
    "GLM-18,low": {
        fill: `rgba(${GLM18_COLOR}, 0.5)`,
        stroke: `rgba(${GLM18_COLOR}, 1)`
    },
    "GLM-18,auto": {
        fill: `rgba(${GLM18_COLOR}, 0.95)`,
        stroke: `rgba(${AUTO_COLOR}, 1)`
    },
    "GLM-17,GLM-18,high": {
        fill: `rgba(${GLM18_COLOR}, 0.95)`,
        stroke: `rgba(${GLM18_COLOR}, 1)`
    },
    "GLM-17,GLM-18,medium": {
        fill: `rgba(${GLM18_COLOR}, 0.7)`,
        stroke: `rgba(${GLM18_COLOR}, 1)`
    },
    "GLM-17,GLM-18,low": {
        fill: `rgba(${GLM18_COLOR}, 0.5)`,
        stroke: `rgba(${GLM18_COLOR}, 1)`
    },
    "GLM-17,GLM-18,auto": {
        fill: `rgba(${GLM18_COLOR}, 0.95)`,
        stroke: `rgba(${AUTO_COLOR}, 1)`
    },


    //Stereo Group Color
    "GLM-16,GLM-17,high": {
        fill: `rgba(${STEREO_COLOR}, 0.95)`,
        stroke: `rgba(${STEREO_COLOR}, 1)`,
    },
    "GLM-16,GLM-17,medium": {
        fill: `rgba(${STEREO_COLOR}, 0.7)`,
        stroke: `rgba(${STEREO_COLOR}, 1)`,
    },
    "GLM-16,GLM-17,low": {
        fill: `rgba(${STEREO_COLOR}, 0.5)`,
        stroke: `rgba(${STEREO_COLOR}, 1)`,
    },
    "GLM-16,GLM-17,auto": {
        fill: `rgba(${STEREO_COLOR}, 0.95)`,
        stroke: `rgba(${AUTO_COLOR}, 1)`,
    },
    "GLM-16,GLM-18,high": {
        fill: `rgba(${STEREO_COLOR}, 0.95)`,
        stroke: `rgba(${STEREO_COLOR}, 1)`,
    },
    "GLM-16,GLM-18,medium": {
        fill: `rgba(${STEREO_COLOR}, 0.7)`,
        stroke: `rgba(${STEREO_COLOR}, 1)`,
    },
    "GLM-16,GLM-18,low": {
        fill: `rgba(${STEREO_COLOR}, 0.5)`,
        stroke: `rgba(${STEREO_COLOR}, 1)`,
    },
    "GLM-16,GLM-18,auto": {
        fill: `rgba(${STEREO_COLOR}, 0.95)`,
        stroke: `rgba(${AUTO_COLOR}, 1)`,
    },
    "GLM-16,GLM-17,GLM-18,high": {
        fill: `rgba(${STEREO_COLOR}, 0.95)`,
        stroke: `rgba(${STEREO_COLOR}, 1)`,
    },
    "GLM-16,GLM-17,GLM-18,medium": {
        fill: `rgba(${STEREO_COLOR}, 0.7)`,
        stroke: `rgba(${STEREO_COLOR}, 1)`,
    },
    "GLM-16,GLM-17,GLM-18,low": {
        fill: `rgba(${STEREO_COLOR}, 0.5)`,
        stroke: `rgba(${STEREO_COLOR}, 1)`,
    },
    "GLM-16,GLM-17,GLM-18,auto": {
        fill: `rgba(${STEREO_COLOR}, 0.95)`,
        stroke: `rgba(${AUTO_COLOR}, 1)`,
    },
}

const C_GLM_LegendItems = {
    "GLM-16,high": {
        label: "GOES-East High Confidence",
        fill: `rgba(${GLM16_COLOR}, 0.95)`,
        stroke: `rgba(${GLM16_COLOR}, 1)`
    },
    "GLM-16,medium": {
        label: "GOES-East Medium Confidence",
        fill: `rgba(${GLM16_COLOR}, 0.7)`,
        stroke: `rgba(${GLM16_COLOR}, 1)`
    },
    "GLM-16,low": {
        label: "GOES-East Low Confidence",
        fill: `rgba(${GLM16_COLOR}, 0.5)`,
        stroke: `rgba(${GLM16_COLOR}, 1)`
    },
    "GLM-16,auto": {
        label: "GOES-East Auto-Publish",
        fill: `rgba(${GLM16_COLOR}, 0.95)`,
        stroke: `rgba(${AUTO_COLOR}, 1)`
    },
    "GLM-17,high": {
        label: "GOES-West High Confidence",
        fill: `rgba(${GLM17_COLOR}, 0.95)`,
        stroke: `rgba(${GLM17_COLOR}, 1)`
    },
    "GLM-17,medium": {
        label: "GOES-West Medium Confidence",
        fill: `rgba(${GLM17_COLOR}, 0.7)`,
        stroke: `rgba(${GLM17_COLOR}, 1)`
    },
    "GLM-17,low": {
        label: "GOES-West Low Confidence",
        fill: `rgba(${GLM17_COLOR}, 0.5)`,
        stroke: `rgba(${GLM17_COLOR}, 1)`
    },
    "GLM-17,auto": {
        label: "GOES-West Auto-Publish",
        fill: `rgba(${GLM17_COLOR}, 0.95)`,
        stroke: `rgba(${AUTO_COLOR}, 1)`
    },
    "GLM-16,GLM-17,high": {
        label: "Stereo High Confidence",
        fill: `rgba(${STEREO_COLOR}, 0.95)`,
        stroke: `rgba(${STEREO_COLOR}, 1)`,
    },
    "GLM-16,GLM-17,medium": {
        label: "Stereo Medium Confidence",
        fill: `rgba(${STEREO_COLOR}, 0.7)`,
        stroke: `rgba(${STEREO_COLOR}, 1)`,
    },
    "GLM-16,GLM-17,low": {
        label: "Stereo Low Confidence",
        fill: `rgba(${STEREO_COLOR}, 0.5)`,
        stroke: `rgba(${STEREO_COLOR}, 1)`,
    },
    "GLM-16,GLM-17,auto": {
        label: "Stereo Auto-Publish",
        fill: `rgba(${STEREO_COLOR}, 0.95)`,
        stroke: `rgba(${AUTO_COLOR}, 1)`,
    },
}

export {
    C_ROLE,
    C_EVENT_IDS,
    C_MODE,
    C_DEFAULTS,
    C_EVENT_STATUS,
    C_MODULE_NAME,
    C_STATUS_MAP_ICON,
    C_LEGEND_LABEL_MAP,
    C_GLM_COLOR,
    C_GLM_LegendItems,
    C_EVENT_SCORES,
    C_EVENT_SCORES_COLORS
}

