import React, {useRef, useState, useEffect } from 'react'
import { makeStyles } from '@mui/styles'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import highchartsAccessibility from "highcharts/modules/accessibility";
import proj4 from 'proj4'

import AppManager from '../../managers/AppStateManager'
import Util from '../../utils/Util'
import World_BaseMap from '../../assets/map/world-base-map-low-res'
import customWrap from "../../utils/highchartsCustomWrap";

// This is to add map plugin
require('highcharts/modules/boost')(Highcharts)
require('highcharts/modules/map')(Highcharts)
highchartsAccessibility(Highcharts);
customWrap(Highcharts) // fix onHover issue when hovering over map points

// This is needed by Highcharts
window.proj4 = proj4

const useStyles = makeStyles({
    root: {
        flex: 1,
        fontSize: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: 0,
        margin: 0,
    },
    container: {
        width: '100%',
        height: 280,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
    }
})

const options = {
    title: {
        style: {
            color: '#303030',
            fontSize: '11px',
        },
        text: 'Location Map',
    },
    credits: {
        enabled: false
    },
    accessibility: {
        description: "World Map showing the latitude / longitude location of the bolide event."
    },
    boost: {
        useGPUTranslations: true
    },
    chart: {
        animation: false,
        width: 10,
        height: 10,
    },
    mapNavigation: {
        enabled: true,
        buttonOptions: {
            verticalAlign: 'bottom',
            y: -20
        }
    },
    xAxis: {
        crosshair: {
            zIndex: 5,
            dashStyle: 'dot',
            snap: false,
            color: 'gray'
        }
    },
    yAxis: {
        crosshair: {
            zIndex: 5,
            dashStyle: 'dot',
            snap: false,
            color: 'gray'
        }
    },
    tooltip: {
        enabled: false,
        followTouchMove: true,
        followPointer: true
    },
    series: [
        {
            name: 'Basemap',
            mapData: World_BaseMap,
            borderColor: '#606060',
            nullColor: 'rgba(200, 200, 200, 0.2)',
            showInLegend: false
        }, 
        {
            name: 'Events',
            type: 'mappoint',
            dataLabels: {
                enabled: false,
            },
            states: {
                hover: {
                    enabled: false,
                }
            },
            marker: {
                fillColor: 'rgba(255,125,0,0.3)',
                lineWidth: 0,
                radius: 17,
            },
            showInLegend: false,
            turboThreshold: 0,
            data: [],
        },
        {
            name: 'EventArea',
            type: 'mappoint',
            dataLabels: {
                enabled: false,
            },
            states: {
                hover: {
                    enabled: false,
                }
            },
            marker: {
                fillColor: 'rgb(255,0,0)',
                lineWidth: 0,
                radius: 1,
                symbol: 'square'
            },
            showInLegend: false,
            data: []
        }
    ]
}

const generateChartOptions = (eventData, geoData, dimension) => {
    const chartOptions = Util.cloneDeep(options)
    let mapTitle = 'Map'
    if (geoData && geoData.length > 0) {
        mapTitle = `Map (${geoData.length} data points)`
    }
    chartOptions.title.text = mapTitle
    if (geoData && geoData.length > 0) {
        const eventDataPoints = [{
            name: `Point_0`,
            lat: geoData[0].location.coordinates[1],
            lon: geoData[0].location.coordinates[0],
        }]
        chartOptions.series[1].data = eventDataPoints
    }
    const poly = Util.generatePolygon(eventData)
    if (poly && poly.length > 0) {
        const areaDataPoints = poly.map((g,idx) => {
            return {
                id: `Area_Point_${idx}`,
                lat: g[1],
                lon: g[0]
            }
        })
        chartOptions.series[2].data = areaDataPoints
    }
    chartOptions.chart = Object.assign({}, chartOptions.chart, {
        width: dimension.width,
        height: dimension.height
    })
    chartOptions.tooltip = Object.assign({}, chartOptions.tooltip, {
        enabled: false
    })
    return chartOptions
}

const pointsToPath = (from, to) => {
    return 'M' + from.x + ',' + -from.y + ',' + to.x + ' ' + -to.y
}


function EventFormMap({ eventData, geoData, attachmentId }) {
    // set aria label for chart for Section 508 issue
    Highcharts.addEvent(Highcharts.Chart, 'render', function () {
        if (this.renderer && this.renderer.box) {
            this.renderer.box.setAttribute('aria-label', "svg-world-chart");
        }
    });

    const classes = useStyles()
    const containerRef = useRef()
    const chartRef = useRef()

    const [dimension, setDimension] = useState({height: 100, width: 100})
    const [chartOptions, setChartOptions] = useState(generateChartOptions(eventData, geoData, dimension))

    useEffect(() => {
        const _chart = chartRef.current
        const _container = containerRef.current

        // console.log('DEBUG: EventFormMap - useEffect()', eventData, geoData)
        const rect = _container.getBoundingClientRect()
        const dim = {height: rect.height, width: rect.width}
        const cOpt = generateChartOptions(eventData, geoData, dim)
        if (!Util.isEqualByValue(cOpt, chartOptions)) {
            setDimension(dim)
            setChartOptions(cOpt)
            return
        }

        
        const updateChartDimension = () => {
            const chart = _chart.chart
            if (chart) {
                const rect = _container.getBoundingClientRect()
                chart.setSize(rect.width, rect.height)
                //console.log(chart)
                if (dimension.width !== rect.width || dimension.height !== rect.height) {
                    const dim = {height: rect.height, width: rect.width}
                    setDimension(dim)
                }
            }
        }
        const addAreaPath = () => {
            const chart = _chart.chart
            // the tranform definition from world-base-map-low-res.json
            const transform = { "crs": "+proj=mill +lat_0=0 +lon_0=0 +x_0=0 +y_0=0 +R_A +datum=WGS84 +units=m +no_defs", "scale": 1.72182781654e-05, "jsonres": 15.5, "jsonmarginX": -999, "jsonmarginY": 9851.0, "xoffset": -19495356.3693, "yoffset": 12635908.1982 }

            const point1 = chart.get('Area_Point_0')
            const p1conv = chart.transformFromLatLon({lat: point1.lat, lon: point1.lon}, transform);
            const point2 = chart.get('Area_Point_1')
            const p2conv = chart.transformFromLatLon({lat: point2.lat, lon: point2.lon}, transform);
            const point3 = chart.get('Area_Point_2')
            const p3conv = chart.transformFromLatLon({lat: point3.lat, lon: point3.lon}, transform);
            const point4 = chart.get('Area_Point_3')
            const p4conv = chart.transformFromLatLon({lat: point4.lat, lon: point4.lon}, transform);
            // console.log(p1conv, " ", p2conv, " ", p3conv, " ", p4conv);
            
            const pathStrings = []
            pathStrings.push(pointsToPath(point1, point2))
            pathStrings.push(pointsToPath(point2, point3))
            pathStrings.push(pointsToPath(point3, point4))
            pathStrings.push(pointsToPath(point4, point1))

            const staticPathStrings = []
            staticPathStrings.push(pointsToPath(p1conv, p2conv))
            staticPathStrings.push(pointsToPath(p2conv, p3conv))
            staticPathStrings.push(pointsToPath(p3conv, p4conv))
            staticPathStrings.push(pointsToPath(p4conv, p1conv))

            
            const paths = [{
                path: pathStrings[0]
            }, {
                path: pathStrings[1]
            }, {
                path: pathStrings[2]
            }, {
                path: pathStrings[3]
            }]
    
            AppManager.eventAttachmentMetaDataMap.set(attachmentId, staticPathStrings)
    
            chart.addSeries({
                name: 'EventAreaPath',
                type: 'mapline',
                lineWidth: 2,
                color: 'rgb(255,0,0)',
                showInLegend: false,
                data: paths
            })
    
            chart.xAxis[0].setExtremes(null, null, false)
            chart.yAxis[0].setExtremes(null, null, false)
            chart.redraw()
        }

        updateChartDimension()
        addAreaPath()

        const handleWindowResize = () => {
            updateChartDimension()
        }


        // TODO: demo this to ATAP folks
        // Zoom over the Americas within the bounds of the svg file
        _chart.chart.mapView.setView(
            [0.0, 0.0],
            -3.50
        )
      
        window.addEventListener("resize", handleWindowResize)
        return () => {
            window.removeEventListener("resize", handleWindowResize)
        }
    }, [chartOptions, eventData, geoData, attachmentId, dimension])


    return (
        <div className={classes.root}>
            <div ref={containerRef} className={classes.container}>
                <HighchartsReact
                    ref={chartRef}
                    highcharts={Highcharts}
                    options={chartOptions}
                    constructorType={'mapChart'}
                />
            </div>
        </div>
    )
}

export default EventFormMap
